import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Warning: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M21.7828 18.5021L13.4422 3.83123C12.8167 2.74059 11.1833 2.7054 10.5578 3.83123L2.21716 18.5021C1.59162 19.5927 2.39092 21 3.67677 21H20.3232C21.6091 21 22.4084 19.6279 21.7828 18.5021ZM12.0174 15.4413C12.8862 15.4413 13.616 16.1801 13.616 17.0596C13.616 17.9744 12.8862 18.678 12.0174 18.678C11.1138 18.678 10.4188 17.9744 10.4188 17.0596C10.4188 16.1801 11.1138 15.4413 12.0174 15.4413ZM10.4883 9.63624C10.4535 9.38997 10.662 9.17888 10.9053 9.17888H13.0947C13.338 9.17888 13.5465 9.38997 13.5117 9.63624L13.2685 14.421C13.2337 14.6673 13.06 14.808 12.8514 14.808H11.1486C10.94 14.808 10.7663 14.6673 10.7315 14.421L10.4883 9.63624Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
