import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Comment: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12.0116 3C6.47116 3 2.0232 6.77679 2.0232 11.3571C2.0232 13.2857 2.76453 15.0536 4.05209 16.4598C3.50585 18.0268 2.29632 19.3929 2.2573 19.3929C1.98418 19.6741 1.94516 20.0759 2.06222 20.4375C2.21828 20.7991 2.56944 21 2.95961 21C5.33966 21 7.2515 19.9955 8.383 19.1518C9.5145 19.5134 10.724 19.7143 12.0116 19.7143C17.513 19.7143 22 15.9777 22 11.3571C22 6.77679 17.513 3 12.0116 3ZM12.0116 18.4286C10.8801 18.4286 9.78762 18.2679 8.73415 17.9464L8.1489 17.7455L7.64167 18.1071C6.74428 18.75 5.37868 19.5134 3.66192 19.7143C4.13013 19.1116 4.79342 18.067 5.22261 16.9018L5.49573 16.1384L4.9885 15.5759C3.85701 14.3304 3.27175 12.8839 3.27175 11.3571C3.27175 7.45982 7.17347 4.28571 12.0116 4.28571C16.8107 4.28571 20.7514 7.45982 20.7514 11.3571C20.7514 15.2545 16.8107 18.4286 12.0116 18.4286ZM14.9379 13.2054C14.2356 14.0893 13.1431 14.5714 12.0116 14.5714C10.8411 14.5714 9.78762 14.0893 9.04629 13.2054C8.81219 12.9241 8.42202 12.8839 8.1489 13.125C7.87578 13.3661 7.87578 13.7679 8.07086 14.0491C9.04629 15.2143 10.4899 15.8571 12.0116 15.8571C13.4943 15.8571 14.9379 15.2143 15.9133 14.0491C16.1474 13.7679 16.1084 13.3661 15.8353 13.125C15.5622 12.8839 15.172 12.9241 14.9379 13.2054ZM9.5145 10.3929C10.0217 10.3929 10.4509 9.99107 10.4509 9.42857C10.4509 8.90625 10.0217 8.46429 9.5145 8.46429C8.96826 8.46429 8.57809 8.90625 8.57809 9.42857C8.57809 9.99107 8.96826 10.3929 9.5145 10.3929ZM14.5087 10.3929C15.0159 10.3929 15.4451 9.99107 15.4451 9.42857C15.4451 8.90625 15.0159 8.46429 14.5087 8.46429C13.9625 8.46429 13.5723 8.90625 13.5723 9.42857C13.5723 9.99107 13.9625 10.3929 14.5087 10.3929Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
