import { classNames } from './class-names';

/**
 * Calls all functions in the order they were chained with the same arguments.
 *
 * Based on `chain` utility from `@react-aria/utils`
 *
 * @see the LICENSE file at the root of this source tree:
 *   https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/chain.ts
 *
 * Modifications from original source: none
 */
export function chain(...callbacks: any[]): (...args: any[]) => void {
  return (...args: any[]) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') callback(...args);
    }
  };
}

/**
 * Merges multiple props objects together. Event handlers are chained and
 * classNames are combined For all other props, the latest prop object overrides
 * earlier ones.
 *
 * Based on `mergeProps` utility from `@react-aria/utils`
 *
 * @see the LICENSE file at the root of this source tree:
 *   https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/mergeProps.ts
 *
 * Modifications from original source:
 *   - Filters `...args` to remove falsey values
 *   - Removes `UNSAFE_className` compatibility
 *   - Removes dependency on `clsx` NPM package
 *   - Removes `id` merging
 *   - Adds `style` merging
 */
export function mergeProps<T extends (Record<string, any> | undefined | null | boolean)[]>(...args: T) {
  const argsFiltered = args.filter(Boolean) as Record<string, any>[];
  const result: Record<string, any> = {};

  for (const props of argsFiltered) {
    for (const key in result) {
      if (/^on[A-Z]/.test(key) && typeof result[key] === 'function' && typeof props[key] === 'function') {
        // Chain event handlers
        result[key] = chain(result[key], props[key]);
      } else if (key === 'className') {
        // Merge class names
        result[key] = classNames([result.className, props.className]);
      } else if (key === 'style' && typeof props[key] === 'object') {
        result[key] = { ...(result[key] ?? {}), ...(props[key] ?? {}) };
      } else {
        // Override others
        result[key] = props[key] !== undefined ? props[key] : result[key];
      }
    }

    // Add props from B that are not in A
    for (const key in props) {
      if (result[key] === undefined) {
        result[key] = props[key];
      }
    }
  }

  return result;
}
