import React from 'react';
import { Inset, FlexRow } from '../../layout';
import { classNames } from '../../../libs/class-names';
import { Icon, MonochromeIcons } from '../../icons';

import styles from './alert.less';

interface AlertProps {
  label?: string;
  type?: 'info' | 'success' | 'error' | 'warning';
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { label, type, children } = props;

  let iconType;
  switch (type) {
    case 'success':
      iconType = MonochromeIcons.SuccessFilled;
      break;

    case 'warning':
      iconType = MonochromeIcons.Warning;
      break;

    case 'error':
      iconType = MonochromeIcons.Exclaim;
      break;

    case 'info':
    default:
      iconType = MonochromeIcons.Info;
      break;
  }

  return (
    <div className={classNames([styles.Alert, styles[type as any]])}>
      <FlexRow vertical="flex-start">
        <Inset top={2}>
          <Icon size={20} type={iconType} />
        </Inset>
        <div role="status" className={styles.message}>
          {label && <div className={styles.label}>{label}</div>}
          {children}
        </div>
      </FlexRow>
    </div>
  );
};

Alert.defaultProps = {
  type: 'info',
};
