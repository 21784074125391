import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Key: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12.8802 6.9978C12.9003 7.48621 12.4905 7.89597 12.0021 7.87585C11.5137 7.8948 11.103 7.48406 11.1219 6.9957C11.1018 6.50728 11.5116 6.09753 12 6.11765C12.4884 6.0987 12.8991 6.50943 12.8802 6.9978ZM13.2327 3.2327L15.7362 5.73625C16.4404 6.44037 16.4222 7.55387 15.7392 8.2368L13.2417 10.7344C13.0075 10.9685 12.7342 11.1245 12.4217 11.2022L12.434 21.4779C12.4342 21.5952 12.3366 21.6927 12.2194 21.6926L11.7896 21.6921C11.6724 21.6919 11.5746 21.5941 11.5745 21.4769L11.5722 19.5624L9.73584 19.5602C9.50142 19.56 9.30583 19.3644 9.30555 19.1299L9.30344 17.3717C9.30316 17.1373 9.49828 16.9422 9.73271 16.9425L11.5691 16.9447L11.5677 15.8116L9.73136 15.8094C9.49693 15.8091 9.30134 15.6135 9.30106 15.3791L9.29895 13.6209C9.29867 13.3865 9.49379 13.1914 9.72822 13.1916L11.5646 13.1938L11.5622 11.2012C11.2495 11.1227 10.9758 10.9661 10.7607 10.7509L8.25714 8.24739C7.55302 7.54326 7.55171 6.44927 8.25415 5.74683L10.7517 3.24927C11.4151 2.54679 12.5481 2.54814 13.2327 3.2327ZM10.1831 17.8221L10.1841 18.6817L11.5516 18.6833L11.5506 17.8237L10.1831 17.8221ZM10.1786 14.0713L10.1796 14.9308L11.5471 14.9325L11.5461 14.0729L10.1786 14.0713ZM8.86048 6.35316C8.50926 6.70438 8.52947 7.27093 8.86197 7.60344L11.3655 10.107C11.7176 10.459 12.2646 10.4597 12.6158 10.1085L15.1134 7.61092C15.4451 7.27921 15.4639 6.7127 15.1119 6.36064L12.6083 3.85709C12.2758 3.52459 11.6897 3.52389 11.358 3.8556L8.86048 6.35316Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
