import React, { ReactHTML } from 'react';
import { classNames } from '../../../libs/class-names';
import { useStyles } from '../../../hooks/use-styles';
import { mergeProps } from '../../../libs/merge-props';

import styles from './inset.less';

interface InsetProps {
  wrapItemsWith?: keyof ReactHTML;
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
  all?: string | number;
  trim?: 'horizontal' | 'vertical';
}

function resolveStyleValue(source?: string | number) {
  return typeof source === 'number' ? `${source}px` : source;
}

export const Inset: React.FC<InsetProps> = (props) => {
  const { wrapItemsWith, top, right, bottom, left, all, trim, children } = props;

  const style = useStyles(
    all
      ? {
          padding: resolveStyleValue(all),
        }
      : {
          paddingTop: resolveStyleValue(top),
          paddingRight: resolveStyleValue(right),
          paddingBottom: resolveStyleValue(bottom),
          paddingLeft: resolveStyleValue(left),
        },
  );

  const numChildren = React.Children.count(children);
  const wrappedChildren = React.Children.map(children, (child, i) => {
    const isFirstChild = i === 0;
    const isLastChild = i === numChildren - 1;

    const className = classNames([
      styles.Inset,
      isFirstChild && styles.isFirstChild,
      isLastChild && styles.isLastChild,
      trim === 'horizontal' && styles.trimHorizontally,
      trim === 'vertical' && styles.trimVertically,
    ]);

    if (wrapItemsWith) {
      return React.createElement(wrapItemsWith, { className, style }, child);
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, mergeProps(child.props, { className, style }));
    }

    return child;
  });

  return <>{wrappedChildren}</>;
};
