import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Asterisk: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M20.7616 15.9844L13.8621 12L20.7616 8.05469C20.9968 7.9375 21.0752 7.66406 20.9184 7.42969L20.448 6.60938C20.3304 6.375 20.056 6.29688 19.8208 6.45312L12.9604 10.3984V2.46875C12.9604 2.23438 12.7252 2 12.49 2H11.5492C11.2748 2 11.0788 2.23438 11.0788 2.46875V10.3984L4.17923 6.45312C3.94402 6.29688 3.66961 6.375 3.552 6.60938L3.08158 7.42969C2.92477 7.66406 3.00318 7.9375 3.23839 8.05469L10.1379 12L3.23839 15.9844C3.00318 16.1016 2.92477 16.375 3.08158 16.6094L3.552 17.4297C3.66961 17.6641 3.94402 17.7422 4.17923 17.5859L11.0788 13.6406V21.5312C11.0788 21.8047 11.2748 22 11.5492 22H12.49C12.7252 22 12.9604 21.8047 12.9604 21.5312V13.6406L19.8208 17.5859C20.056 17.7422 20.3304 17.6641 20.448 17.4297L20.9184 16.6094C21.0752 16.375 20.9968 16.1016 20.7616 15.9844Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
