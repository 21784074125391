import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const TrafficCone: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M34.625 22H21.3125L18.9375 28H37L34.625 22ZM30.0625 10.6875C29.9375 10.3125 29.5625 10.0625 29.125 10H26.8125C26.375 10.0625 26 10.3125 25.875 10.6875L22.9375 18H33L30.0625 10.6875ZM43 38H41L38.5625 32H17.375L15 38H13C12.4375 38 12 38.5 12 39V41C12 41.5625 12.4375 42 13 42H43C43.5 42 44 41.5625 44 41V39C44 38.5 43.5 38 43 38Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.error.base : theme.hex.error.darker),
  colorB: (theme) => theme.hex.error.lightest,
};
