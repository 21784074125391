import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDomEvent, useIsMounted } from 'usable-react';
import { MonochromeIconDefinition } from '../../icons';
import { Tab } from './tab/tab';
import { TabList } from './tab-list/tab-list';
import { TabPanel } from './tab-panel';

interface TabConfig {
  id: React.Key;
  label: JSX.Element | React.ReactText;
  content: JSX.Element;
  icon?: MonochromeIconDefinition;
}

interface UseTabsConfig {
  id?: React.Key;
  label: string;
  size?: 'sm' | 'md' | 'lg';
  orientation?: 'horizontal' | 'vertical';
  tabs: TabConfig[];
}

function formatTabID(tablistID: React.Key = 0, key: React.Key) {
  return `mg-useTabs-tabID__${tablistID}-${key}`;
}

function formatPanelID(tablistID: React.Key = 0, key: React.Key) {
  return `mg-useTabs-panelID__${tablistID}-${key}`;
}

export function useTabs(config: UseTabsConfig) {
  const { id: tablistID, label: tablistLabel, size = 'md', orientation = 'horizontal', tabs } = config;

  const isMounted = useIsMounted();

  const tabIDs = tabs.map(({ id }) => formatTabID(tablistID, id));
  const panelIDs = tabs.map(({ id }) => formatPanelID(tablistID, id));
  const [useAnimation, setUseAnimation] = useState(false);
  const [activeTabID, setActiveTabID] = useState<string | undefined>(tabIDs[0]);
  const [indicatorAttributes, setIndicatorAttributes] = useState<
    React.ComponentProps<typeof TabList>['indicatorAttributes']
  >({
    width: 0,
    height: 0,
    offsetLeft: 0,
    offsetTop: 0,
  });

  // We save a `tabsRef` using the `tabsRefCallback` to get a current list of
  // React refs for each rendered tab button.
  const tabRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});
  const tabRefsCallback = useCallback((node: HTMLButtonElement | null) => {
    if (node) tabRefs.current[node.id] = node;
  }, []);

  /**
   * Updates the saved `width` and `offsetLeft` attributes to re-position the
   * selected tab indicator.
   */
  const updateAttributes = useCallback((tabID: string) => {
    const ref = tabRefs.current[tabID];

    if (ref && isMounted()) {
      setIndicatorAttributes({
        width: ref.getBoundingClientRect().width,
        height: ref.getBoundingClientRect().height,
        offsetLeft: ref.offsetLeft,
        offsetTop: ref.offsetTop,
      });
    }
  }, []);

  /**
   * Sets the active tab ID and updates the selected tab indicator attributes.
   */
  const activateTab = useCallback(
    (tabID: string) => {
      if (isMounted()) {
        setActiveTabID(tabID);
        updateAttributes(tabID);
        setUseAnimation(true);
      }
    },

    [updateAttributes],
  );

  // Set the initial selected tab indicator attributes.
  useEffect(() => {
    updateAttributes(activeTabID!);
  }, []);

  // If the `window` resizes, we update the selected tab indicator attributes.
  const addEvent = useDomEvent(window);
  addEvent('resize', () => {
    updateAttributes(activeTabID!);
    setUseAnimation(true);
  });

  const tablist = (
    <TabList
      orientation={orientation}
      label={tablistLabel}
      indicatorAttributes={indicatorAttributes}
      useAnimation={useAnimation}
    >
      {tabs.map(({ id, label, icon }, i) => {
        const isSelected = activeTabID === tabIDs[i];
        return (
          <Tab
            id={tabIDs[i]}
            panelID={panelIDs[i]}
            allTabIDs={tabIDs}
            index={i}
            activateTab={activateTab}
            selected={isSelected}
            orientation={orientation}
            size={size}
            useAnimation={useAnimation}
            ref={tabRefsCallback}
            icon={icon}
            key={id}
          >
            {label}
          </Tab>
        );
      })}
    </TabList>
  );

  const tabpanel = tabs.map(({ id, content }, i) => {
    return (
      <TabPanel id={panelIDs[i]} tabID={tabIDs[i]} selected={activeTabID === tabIDs[i]} key={id}>
        {content}
      </TabPanel>
    );
  });

  return { tablist, tabpanel };
}
