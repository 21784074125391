import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Lightbulb: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M8.81818 19.7388C8.81818 19.9727 8.85795 20.2066 9.01705 20.4405L9.69318 21.4152C9.93182 21.7661 10.3295 22 10.767 22H13.1932C13.6307 22 14.0284 21.7661 14.267 21.4152L14.9432 20.4405C15.0625 20.2066 15.142 19.9727 15.142 19.7388V18.2183H8.81818V19.7388ZM5 8.8616C5 10.616 5.63636 12.1754 6.71023 13.384C7.38636 14.1248 8.38068 15.6842 8.77841 16.9708C8.77841 16.9708 8.77841 16.9708 8.77841 17.0097H15.1818C15.1818 16.9708 15.1818 16.9708 15.1818 16.9708C15.5795 15.6842 16.5739 14.1248 17.25 13.384C18.3239 12.1754 19 10.616 19 8.8616C19 5.07992 15.8182 2 11.9602 2C7.90341 2.03899 5 5.23587 5 8.8616ZM12 5.74269C10.2102 5.74269 8.81818 7.1462 8.81818 8.8616C8.81818 9.21248 8.5 9.48538 8.18182 9.48538C7.82386 9.48538 7.54545 9.21248 7.54545 8.8616C7.54545 6.48343 9.53409 4.49513 12 4.49513C12.3182 4.49513 12.6364 4.80702 12.6364 5.11891C12.6364 5.46979 12.3182 5.74269 12 5.74269Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
