import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Profile: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M15.1786 13.125C14.1429 13.125 13.6786 13.6875 12 13.6875C10.2857 13.6875 9.82143 13.125 8.78571 13.125C6.14286 13.125 4 15.2695 4 17.8711V19.3125C4 20.2617 4.75 21 5.71429 21H18.2857C19.2143 21 20 20.2617 20 19.3125V17.8711C20 15.2695 17.8214 13.125 15.1786 13.125ZM18.8571 19.3125C18.8571 19.6289 18.5714 19.875 18.2857 19.875H5.71429C5.39286 19.875 5.14286 19.6289 5.14286 19.3125V17.8711C5.14286 15.8672 6.75 14.25 8.78571 14.25C9.5 14.25 10.1786 14.8125 12 14.8125C13.7857 14.8125 14.5 14.25 15.1786 14.25C17.2143 14.25 18.8571 15.8672 18.8571 17.8711V19.3125ZM12 12C14.5 12 16.5714 9.99609 16.5714 7.5C16.5714 5.03906 14.5 3 12 3C9.46429 3 7.42857 5.03906 7.42857 7.5C7.42857 9.99609 9.46429 12 12 12ZM12 4.125C13.8571 4.125 15.4286 5.67188 15.4286 7.5C15.4286 9.36328 13.8571 10.875 12 10.875C10.1071 10.875 8.57143 9.36328 8.57143 7.5C8.57143 5.67188 10.1071 4.125 12 4.125Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
