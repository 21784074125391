import React from 'react';
import { forwardRefWrapper } from '../../libs/forward-ref-wrapper';

interface SpacerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: string | number;
  vertical?: boolean;
  inline?: boolean;
}

export const Spacer = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, SpacerProps>(
  'Spacer',

  { size: 0 },

  (props, externalRef) => {
    const { size, vertical, inline, ...otherProps } = props;

    const horizontalStyle = { display: inline ? 'inline-block' : 'block', height: '100%', width: size };
    const verticalStyle = { display: inline ? 'inline-block' : 'block', width: '100%', height: size };
    const style = vertical ? verticalStyle : horizontalStyle;

    return <div aria-hidden="true" style={style} {...otherProps} ref={externalRef} />;
  },
);
