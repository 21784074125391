import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const SuccessOutlined: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12C22 6.51613 17.4839 2 12 2ZM12 20.7097C7.20161 20.7097 3.29032 16.8387 3.29032 12C3.29032 7.24194 7.16129 3.29032 12 3.29032C16.7581 3.29032 20.7097 7.20161 20.7097 12C20.7097 16.7984 16.7984 20.7097 12 20.7097ZM17.6855 9.66129C17.8871 9.45968 17.8871 9.1371 17.6855 8.97581L17.3629 8.6129C17.1613 8.41129 16.8387 8.41129 16.6774 8.6129L10.0645 15.1452L7.28226 12.3629C7.12097 12.1613 6.79839 12.1613 6.59677 12.3629L6.27419 12.6855C6.07258 12.8871 6.07258 13.1694 6.27419 13.371L9.74194 16.879C9.90323 17.0403 10.2258 17.0403 10.4274 16.879L17.6855 9.66129Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
