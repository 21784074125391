import React from 'react';
import { PressEvent } from '@react-types/shared';
import { usePress } from '@react-aria/interactions';
import { useFocusRing } from '@react-aria/focus';
import { AnimatePresence, motion } from 'framer-motion';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { Icon, MonochromeIconDefinition, IconColorProvider } from '../../icons';
import { Spacer } from '../../layout';
import { useScaleIn } from '../../../hooks/transitions';
import { InverseThemeSemantics } from '../../theme/inverse-theme-semantics';
import { BaseColorName } from '../../../libs/theme';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';
import { useTheme } from '../../theme';

import styles from './call-to-action.less';

interface CallToActionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean;
  disabled?: boolean;
  onPress?: (event: PressEvent) => void;
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error';
  leadingIcon?: MonochromeIconDefinition;
  trailingIcon?: MonochromeIconDefinition;
}

/**
 * Determines a _default_ color for icons nested in a `<CallToAction>`.
 */
function useIconColor(baseColor: CallToActionProps['color'], inverseSemantics?: boolean, outline?: boolean) {
  const theme = useTheme({ inverseSemantics });

  let iconBaseColor: BaseColorName | undefined;

  switch (baseColor) {
    case 'primary':
      iconBaseColor = outline ? 'tertiary' : 'secondary';
      break;

    case 'secondary':
      iconBaseColor = outline ? 'secondary' : 'tertiary';
      break;

    case 'tertiary':
      iconBaseColor = outline ? 'tertiary' : 'secondary';
      break;

    case 'error':
      iconBaseColor = outline ? 'error' : 'secondary';
      break;

    case 'warning':
      iconBaseColor = outline ? 'warning' : 'secondary';
      break;

    case 'success':
      iconBaseColor = outline ? 'success' : 'secondary';
      break;

    default:
      iconBaseColor = undefined;
  }

  return iconBaseColor ? theme.hex[iconBaseColor].base : undefined;
}

export const CallToAction = /* @__PURE__ */ forwardRefWrapper<HTMLButtonElement, CallToActionProps>(
  'CallToAction',

  { color: 'primary', size: 'md' },

  (props, externalRef) => {
    const {
      children,
      onPress,
      className,
      disabled,
      outline,
      color,
      size,
      leadingIcon,
      trailingIcon,
      ...otherProps
    } = props;

    const { isFocusVisible, focusProps } = useFocusRing();
    const { pressProps, isPressed } = usePress({ onPress, isDisabled: disabled });
    const getScaleProps = useScaleIn();

    const inverseSemantics = (color === 'secondary' && outline) || (color === 'tertiary' && !outline);
    const iconColor = useIconColor(color, inverseSemantics, outline);

    return (
      <IconColorProvider colorA={iconColor}>
        <InverseThemeSemantics active={inverseSemantics}>
          <button
            className={classNames([
              className,
              styles.CallToAction,
              styles[`size_${size}`],
              styles[`color_${color}`],
              disabled && styles.disabled,
              outline && styles.outline,
              isPressed && styles.active,
            ])}
            type="button"
            disabled={disabled}
            aria-disabled={disabled}
            {...mergeProps(pressProps, focusProps, otherProps)}
            ref={externalRef}
          >
            <AnimatePresence>
              {isFocusVisible && (
                <motion.span aria-hidden="true" className={styles.focusRing} {...getScaleProps(1.05)} />
              )}
            </AnimatePresence>

            {leadingIcon && (
              <>
                <Icon size={size === 'sm' ? 20 : 24} type={leadingIcon} />
                <Spacer size={8} />
              </>
            )}

            <span className={styles.buttonLabel}>{children}</span>

            {trailingIcon && (
              <>
                <Spacer size={8} />
                <Icon size={size === 'sm' ? 20 : 24} type={trailingIcon} />
              </>
            )}
          </button>
        </InverseThemeSemantics>
      </IconColorProvider>
    );
  },
);
