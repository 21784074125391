import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const AsteriskBold: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M3.47671 15.0859L8.91629 12L3.47671 8.95312C3.01702 8.71875 2.8638 8.13281 3.13194 7.66406L3.89808 6.375C4.12793 5.90625 4.70253 5.75 5.16221 6.02344L10.4486 9.30469L10.3336 2.97656C10.3336 2.46875 10.755 2 11.253 2H12.747C13.245 2 13.6664 2.46875 13.6664 2.97656L13.5131 9.30469L18.8378 6.02344C19.2975 5.75 19.8721 5.90625 20.1019 6.375L20.8681 7.66406C21.1362 8.13281 20.983 8.71875 20.5233 8.95312L15.0454 12L20.5233 15.0859C20.983 15.3203 21.1362 15.9062 20.8681 16.375L20.1019 17.6641C19.8721 18.1328 19.2975 18.2891 18.8378 18.0156L13.5131 14.7344L13.6664 21.0625C13.6664 21.5703 13.245 22 12.747 22H11.253C10.755 22 10.3336 21.5703 10.3336 21.0625L10.4486 14.7344L5.16221 18.0156C4.70253 18.2891 4.12793 18.1328 3.89808 17.6641L3.13194 16.375C2.8638 15.9062 3.01702 15.3203 3.47671 15.0859Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
