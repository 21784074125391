import { useFocusVisible, useFocusWithin } from '@react-aria/interactions';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useFade, useScaleIn } from '../../../../hooks/transitions';
import { useStyles } from '../../../../hooks/use-styles';
import { classNames } from '../../../../libs/class-names';
import { FlexContainer } from '../../flexbox/flexbox';

import styles from './tab-list.less';

interface TabListProps {
  label: string;
  orientation: 'horizontal' | 'vertical';
  indicatorAttributes: { width: number; height: number; offsetLeft: number; offsetTop: number };
  useAnimation: boolean;
}

export const TabList: React.FC<TabListProps> = (props) => {
  const { label, orientation, indicatorAttributes, useAnimation, children } = props;

  const [isFocused, setFocus] = useState(false);
  const { focusWithinProps } = useFocusWithin({ onFocusWithinChange: setFocus });
  const { isFocusVisible } = useFocusVisible();
  const getFadeProps = useFade();
  const getScaleProps = useScaleIn();
  const focusRingAnimationProps = orientation === 'vertical' ? getScaleProps(1.1) : getFadeProps();

  const horizontalTabStyle = useStyles({
    width: `${indicatorAttributes.width}px`,
    left: `${indicatorAttributes.offsetLeft}px`,
  });

  const verticalTabStyle = useStyles({
    height: `${indicatorAttributes.height}px`,
    top: `${indicatorAttributes.offsetTop}px`,
  });

  const verticalFocusRingStyle = useStyles({
    height: `calc(${verticalTabStyle.height} + 4px)`,
    top: `calc(${verticalTabStyle.top} - 4px)`,
  });

  return (
    <FlexContainer
      inline
      className={classNames([styles.TabList, styles[orientation]])}
      direction={orientation === 'vertical' ? 'column' : 'row'}
      alignItems={orientation === 'vertical' ? undefined : 'center'}
      justifyContent={orientation === 'vertical' ? 'center' : undefined}
      role="tablist"
      aria-label={label}
      {...focusWithinProps}
    >
      <AnimatePresence>
        {isFocused && isFocusVisible && (
          <motion.span
            aria-hidden="true"
            className={styles.focusRing}
            {...focusRingAnimationProps}
            transition={{ duration: 0.3 }}
            style={orientation === 'vertical' ? verticalFocusRingStyle : undefined}
          />
        )}
      </AnimatePresence>

      <div
        aria-hidden="true"
        className={classNames([styles.selectedTabIndicator, useAnimation && styles.withAnimation])}
        style={orientation === 'vertical' ? verticalTabStyle : horizontalTabStyle}
      />
      {children}
    </FlexContainer>
  );
};
