import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const NotificationOff: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12.0201 20.75C11.2965 20.75 10.7337 20.2031 10.7337 19.5H9.44722C9.44722 20.9062 10.5729 22 12.0201 22C13.4271 22 14.593 20.9062 14.593 19.5H13.3065C13.3065 20.2031 12.7035 20.75 12.0201 20.75ZM20.4222 15.0859C19.2965 14.0703 18.4523 12.9766 18.4523 9.26563C18.4523 6.17969 15.8794 3.64063 12.6633 3.32813V2.625C12.6633 2.3125 12.3417 2 12.0201 2C11.6583 2 11.3769 2.3125 11.3769 2.625V3.32813C8.12058 3.64063 5.54771 6.17969 5.54771 9.26563C5.54771 12.9766 4.70348 14.0703 3.57785 15.0859C3.01503 15.6328 2.85422 16.4141 3.13563 17.1172C3.41704 17.8203 4.14066 18.25 4.94469 18.25H19.0955C19.8593 18.25 20.583 17.8203 20.8644 17.1172C21.1458 16.4141 20.985 15.6328 20.4222 15.0859ZM19.0955 17H4.94469C4.34167 17 4.06026 16.375 4.46227 15.9844C5.86931 14.6953 6.83414 13.25 6.83414 9.26563C6.83414 6.64844 9.16581 4.5 12.0201 4.5C14.8342 4.5 17.1659 6.64844 17.1659 9.26563C17.1659 13.25 18.1307 14.6953 19.5377 15.9844C19.9397 16.375 19.6583 17 19.0955 17Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
