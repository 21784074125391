import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const EyeOpen: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0174 5C16.2609 5 20.0174 7.625 21.8957 11.4896C21.9652 11.6354 22 11.8542 22 12C22 12.1823 21.9652 12.401 21.8957 12.5469C20.0174 16.4115 16.2609 19 12.0174 19C7.73913 19 3.98261 16.4115 2.10435 12.5469C2.03478 12.401 2 12.1823 2 12.0365C2 11.8542 2.03478 11.6354 2.10435 11.4896C3.98261 7.625 7.73913 5 12.0174 5ZM3.73913 12C5.4087 15.2448 8.57391 17.25 12.0174 17.25C15.4261 17.25 18.5913 15.2448 20.2609 12C18.5913 8.75521 15.4261 6.75 12.0174 6.75C8.57391 6.75 5.4087 8.75521 3.73913 12ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
