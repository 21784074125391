import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Refresh: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M22 11.9803C22 10.5644 21.4922 9.26647 20.7109 8.20452C20.5547 7.96853 20.2031 7.96853 20.0078 8.16519L19.7734 8.40118C19.6172 8.55851 19.6172 8.79449 19.7344 8.99115C20.3594 9.85644 20.75 10.8791 20.75 11.9803C20.75 14.7729 18.4844 17.0147 15.75 17.0147H6.29688L8.95312 14.3402C9.14844 14.1829 9.14844 13.8682 8.95312 13.6716L8.75781 13.4749C8.5625 13.2783 8.25 13.2783 8.09375 13.4749L4.22656 17.3294C4.07031 17.5261 4.07031 17.8014 4.22656 17.998L8.09375 21.8525C8.25 22.0492 8.5625 22.0492 8.75781 21.8525L8.95312 21.6558C9.14844 21.4592 9.14844 21.1445 8.95312 20.9872L6.29688 18.2734H15.75C19.1875 18.2734 22 15.4808 22 11.9803ZM4.22656 15.0088C3.60156 14.1436 3.25 13.1209 3.25 11.9803C3.25 9.22714 5.47656 6.94592 8.25 6.94592H17.6641L15.0078 9.65978C14.8125 9.81711 14.8125 10.1318 15.0078 10.3284L15.2031 10.5251C15.3984 10.7217 15.7109 10.7217 15.8672 10.5251L19.7344 6.6706C19.8906 6.47394 19.8906 6.19862 19.7344 6.00197L15.8672 2.14749C15.7109 1.95084 15.3984 1.95084 15.2031 2.14749L15.0078 2.34415C14.8125 2.54081 14.8125 2.85546 15.0078 3.01278L17.6641 5.68732H8.25C4.77344 5.68732 2 8.51917 2 11.9803C2 13.4356 2.46875 14.7335 3.25 15.7955C3.40625 16.0315 3.75781 16.0315 3.95312 15.8348L4.1875 15.5988C4.34375 15.4415 4.34375 15.2055 4.22656 15.0088Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
