import { AnimationProps, TargetAndTransition, MotionAdvancedProps } from 'framer-motion';
import { useCallback } from 'react';

export function createFramerTransition<TCustom = void>() {
  return {
    withVariants: <VariantKeys extends string = string>(
      variants: { [P in VariantKeys]: TargetAndTransition | ((custom: TCustom) => TargetAndTransition) },
    ) => ({
      use: (targets: {
        initial?: VariantKeys;
        animate?: VariantKeys;
        exit?: VariantKeys;
      }): ((custom?: TCustom) => AnimationProps & MotionAdvancedProps) => {
        const { initial, animate, exit } = targets;
        return useCallback((custom = {} as any) => ({ custom, variants, initial, animate, exit }), [
          initial,
          animate,
          exit,
        ]);
      },
    }),
  };
}

const fadeTransition = /* @__PURE__ */ createFramerTransition().withVariants({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
});

export function useFade() {
  return fadeTransition.use({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
  });
}

const scaleTransition = /* @__PURE__ */ createFramerTransition<number>().withVariants({
  hiddenWithScale: (scale) => ({ opacity: 0, scale, transition: { duration: 0.1 } }),
  hiddenWithoutScale: { opacity: 0, scale: 1, transition: { duration: 0.1 } },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.1 } },
});

export function useScale() {
  return scaleTransition.use({
    initial: 'hiddenWithScale',
    animate: 'visible',
    exit: 'hiddenWithScale',
  });
}

export function useScaleIn() {
  return scaleTransition.use({
    initial: 'hiddenWithScale',
    animate: 'visible',
    exit: 'hiddenWithoutScale',
  });
}

export function useScaleOut() {
  return scaleTransition.use({
    initial: 'hiddenWithoutScale',
    animate: 'visible',
    exit: 'hiddenWithScale',
  });
}

const slideTransition = /* @__PURE__ */ createFramerTransition<{ x?: number; y?: number }>().withVariants({
  hidden: ({ x = 0, y = 0 }) => ({ x, y, opacity: 0, transition: { ease: 'easeInOut', duration: 0.15 } }),
  visible: { x: 0, y: 0, opacity: 1, transition: { ease: 'easeInOut', duration: 0.15 } },
});

export function useSlide() {
  return slideTransition.use({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
  });
}
