import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const SuccessFilled: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M22 12C22 6.51613 17.4839 2 12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12ZM10.8306 17.3226C10.5887 17.5645 10.1452 17.5645 9.90323 17.3226L5.70968 13.129C5.46774 12.8871 5.46774 12.4435 5.70968 12.2016L6.6371 11.3145C6.87903 11.0323 7.28226 11.0323 7.52419 11.3145L10.3871 14.1371L16.4355 8.08871C16.6774 7.80645 17.0806 7.80645 17.3226 8.08871L18.25 8.97581C18.4919 9.21774 18.4919 9.66129 18.25 9.90323L10.8306 17.3226Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
