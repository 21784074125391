import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Remove: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.2073 19.9022L30.07 27.9605L38.9803 36.8709C38.9815 37.0183 38.9299 37.1748 38.8257 37.3138L37.3135 38.826C37.1746 38.9302 37.0181 38.9818 36.8707 38.9806L36.0976 38.2076L28.0392 30.0702L19.1288 38.9806C18.9814 38.9818 18.8249 38.9302 18.6859 38.826L17.1737 37.3138C17.0695 37.1749 17.018 37.0184 17.0191 36.871L25.9293 27.9607L17.019 19.1223C17.0195 18.9769 17.0711 18.823 17.1737 18.6862L18.6859 17.174C18.8249 17.0698 18.9814 17.0182 19.1288 17.0194L28.039 25.9296L36.8774 17.0194C37.0228 17.0199 37.1767 17.0714 37.3135 17.174L38.8257 18.6862C38.9299 18.8252 38.9815 18.9817 38.9803 19.1291L38.2073 19.9022Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.mid.darkest : theme.hex.mid.lightest),
  colorB: (theme) => (theme.isLightTheme ? theme.hex.mid.lightest : theme.hex.mid.darkest),
};
