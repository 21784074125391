import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ResizePayload, watchResize } from 'watch-resize';
import composeRefs from '@seznam/compose-react-refs';
import { cssTimeToMilliseconds } from '../../libs/css-time-to-milliseconds';
import { forwardRefWrapper } from '../../libs/forward-ref-wrapper';

interface TransitionStyles {
  transition: string;
  height: string;
  width: string;
  willChange: string;
}

interface TransitionConfig {
  duration?: string;
  timingFunction?: string;
  delay?: string;
}

interface FlexibleDivProps extends React.HTMLAttributes<HTMLDivElement> {
  initialDelay?: number;
  transition?: boolean | TransitionConfig;
  applyCSSVariables?: {
    element?: HTMLElement;
    transitionVar?: string;
    heightVar?: string;
    widthVar?: string;
  };
  onResize?: (
    payload: Omit<ResizePayload<HTMLElement>, 'destroy'> & {
      transitionStyles: TransitionStyles;
    },
  ) => void;
  onAnimationFrame?: FrameRequestCallback;
  observerOnly?: boolean;
  autoWidth?: boolean;
  autoHeight?: boolean;
}

/**
 * This component is a container which automatically updates its `height` style
 * property based on the total size of its child nodes. This enables dynamic
 * transitional height behavior.
 */
export const FlexibleDiv = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexibleDivProps>(
  'FlexibleDiv',

  {
    initialDelay: 0,
    transition: true,
    applyCSSVariables: {},
    onResize: () => {},
    style: {},
    observerOnly: false,
    autoWidth: false,
    autoHeight: false,
  },

  (props, externalRef) => {
    // --- Data

    const contentElement = useRef<HTMLElement>();
    const [height, setHeight] = useState<string | number>('auto');
    const [width, setWidth] = useState<string | number>('auto');

    const isMounted = useRef(false);
    const isTransitioning = useRef(false);
    const raf = useRef<number | null>(null);

    // --- Utilities for handling `watch-resize` observer

    /**
     * Set the `containerElement` ref height based on the given element
     * or `contentElement` ref as a fallback.
     */
    const setContainerElementDimensions = useCallback(
      (domRect?: DOMRect) => {
        let rect = domRect;
        if (!rect) {
          if (contentElement.current) rect = contentElement.current.getBoundingClientRect();
          else return;
        }

        if (!props.autoHeight) {
          const nextHeight = Math.ceil(rect.height);
          if (nextHeight !== height) setHeight(`${nextHeight}px`);
        }

        if (!props.autoWidth) {
          const nextWidth = Math.ceil(rect.width);
          if (nextWidth !== width) setWidth(`${nextWidth}px`);
        }
      },
      [height, width],
    );

    /**
     * Build styles to transition height.
     */
    const buildTransitionStyles = useCallback(
      (heightOverride?: number, widthOverride?: number): TransitionStyles => {
        if (props.transition) {
          let transition = 'height 0.2s ease, width 0.2s ease';

          if (typeof props.transition !== 'boolean') {
            const { duration, timingFunction, delay } = props.transition;

            transition = `height${duration ? `${duration} ` : ' 0.2s'}${
              timingFunction ? `${timingFunction} ` : ' ease'
            }${delay ? `${delay} ` : ' 0s'}`;

            transition += `, width${duration ? `${duration} ` : ' 0.2s'}${
              timingFunction ? `${timingFunction} ` : ' ease'
            }${delay ? `${delay} ` : ' 0s'}`;
          }

          const resolvedHeightOverride = heightOverride ? `${Math.ceil(heightOverride)}px` : null;
          const resolvedHeight = typeof height === 'string' ? height : `${Math.ceil(height)}px`;

          const resolvedWidthOverride = widthOverride ? `${Math.ceil(widthOverride)}px` : null;
          const resolvedWidth = typeof width === 'string' ? width : `${Math.ceil(width)}px`;

          return {
            transition,
            height: props.autoHeight ? 'auto' : resolvedHeightOverride || resolvedHeight,
            width: props.autoWidth ? 'auto' : resolvedWidthOverride || resolvedWidth,
            willChange: 'height, width',
          };
        }

        return {
          transition: 'none',
          height: 'auto',
          width: 'auto',
          willChange: 'unset',
        };
      },
      [props.transition, height, width],
    );

    /**
     * Set CSS variables for the generated `transition` and `height` properties.
     */
    const applyCSSVariables = useCallback(
      (heightOverride?: number, widthOverride?: number) => {
        const { element, heightVar, widthVar, transitionVar } = props.applyCSSVariables!;
        const transitionStyles = buildTransitionStyles(heightOverride, widthOverride);
        const elementResolved = element || document.documentElement;

        if (heightVar)
          elementResolved.style.setProperty(heightVar, props.autoHeight ? 'auto' : transitionStyles.height);
        if (widthVar) elementResolved.style.setProperty(widthVar, props.autoWidth ? 'auto' : transitionStyles.width);
        if (transitionVar) elementResolved.style.setProperty(transitionVar, transitionStyles.transition);
      },
      [
        props.applyCSSVariables?.element,
        props.applyCSSVariables?.heightVar,
        props.applyCSSVariables?.widthVar,
        props.applyCSSVariables?.transitionVar,
      ],
    );

    /**
     * Create the resize stream and start observing.
     */
    const setupResizeObservable = useCallback(async (): Promise<() => void> => {
      /*
        Under the hood, `watchResize` is creating a nested browsing
        context inside a DOM `<object>`. This nested context has a
        `window` object that provides the stream of "resize" events.
       */
      const destroy = await watchResize(contentElement.current!, (payload) => {
        isTransitioning.current = true;
        setTimeout(() => {
          if (isMounted.current) isTransitioning.current = false;
        }, cssTimeToMilliseconds((props.transition as TransitionConfig)?.duration) + 100);

        const rect = payload.element.getBoundingClientRect();
        props.onResize?.({ ...payload, transitionStyles: buildTransitionStyles(rect.height, rect.width) });
        applyCSSVariables(rect.height, rect.width);
        if (!props.observerOnly) setContainerElementDimensions(rect);
      });

      return destroy;
    }, [setContainerElementDimensions, props.observerOnly, buildTransitionStyles, applyCSSVariables, props.transition]);

    /**
     * Stop observing the resize stream and destroy the subscription (the stream
     * itself will be garbage collected).
     */
    const teardownResizeObservable = useCallback((destroy?: () => void) => {
      if (destroy) destroy();
    }, []);

    // --- React lifecycle

    // Setup/teardown the resize observable on component mount/unmount
    useEffect(() => {
      let destroyResizeListener: () => void;
      let timeout: any;
      isMounted.current = true;

      (async () => {
        if (props.initialDelay) {
          timeout = setTimeout(async () => {
            if (!props.observerOnly) setContainerElementDimensions();
            destroyResizeListener = await setupResizeObservable();
          }, props.initialDelay);
        } else {
          setContainerElementDimensions();
          destroyResizeListener = await setupResizeObservable();
        }
      })();

      return () => {
        isMounted.current = false;
        if (timeout) clearTimeout(timeout);
        teardownResizeObservable(destroyResizeListener);
      };
    }, []);

    useEffect(() => {
      if (isTransitioning.current && props.onAnimationFrame) {
        const tick = () => {
          raf.current = requestAnimationFrame((...args) => {
            props.onAnimationFrame!(...args);
            if (isTransitioning.current) tick();
          });
        };

        tick();
      } else {
        return () => {
          if (raf.current) cancelAnimationFrame(raf.current);
        };
      }

      return undefined;
    });

    // --- Rendering

    const {
      children: _1, // unused
      style: _2, // unused
      initialDelay: _3, // unused
      transition: _4, // unused
      onResize: _5, // unused
      observerOnly: _6, // unused
      applyCSSVariables: _7, // unused
      onAnimationFrame: _8, // unused
      autoWidth: _9, // unused
      autoHeight: _10, // unused
      ...otherProps
    } = props;

    return props.observerOnly ? (
      <div style={props.style} {...otherProps} ref={composeRefs<any>(contentElement, externalRef)}>
        {props.children}
      </div>
    ) : (
      <div
        style={{
          position: 'relative',
          ...props.style,
          ...buildTransitionStyles(),
        }}
        {...otherProps}
      >
        <div
          ref={composeRefs<any>(contentElement, externalRef)}
          style={{ position: width === 'auto' ? undefined : 'absolute' }}
        >
          {props.children}
        </div>
      </div>
    );
  },
);
