import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';

let portalID = 0;

export const EphemeralPortal: React.FC = ({ children }) => {
  const [portalContext, setPortalContext] = useState<HTMLDivElement | null>(null);

  // Setup/teardown the tooltip context
  useIsomorphicLayoutEffect(() => {
    const container = document.createElement('div');
    container.dataset.mgEphemeralPortal = String(++portalID);
    document.body.appendChild(container);
    setPortalContext(container);
    return () => container.remove();
  }, []);

  return portalContext ? <>{ReactDOM.createPortal(children, portalContext)}</> : null;
};
