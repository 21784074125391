/* eslint-disable no-nested-ternary */

import React from 'react';
import { classNames } from '../../../libs/class-names';
import { useStyles } from '../../../hooks/use-styles';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';

import styles from './flexbox.less';

type MainAxisAlignment =
  | 'normal'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'left'
  | 'right'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'safe'
  | 'unsafe';

type CrossAxisAlignment =
  | 'normal'
  | 'flex-start'
  | 'flex-end'
  | 'self-start'
  | 'self-end'
  | 'center'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'stretch'
  | 'safe'
  | 'unsafe';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type FlexWrap = boolean | 'reverse';

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean;

  // Flex alignment
  justifyContent?: MainAxisAlignment;
  alignItems?: CrossAxisAlignment;
  alignSelf?: CrossAxisAlignment | 'auto';
  alignContent?: CrossAxisAlignment;

  // Flex flow properties
  flex?: string;
  direction?: FlexDirection;
  wrap?: FlexWrap;
  grow?: number;
  shrink?: number;
  basis?: string;
}

const FlexLayout = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexProps>('FlexLayout', (props, externalRef) => {
  const {
    inline,

    justifyContent,
    alignItems,
    alignSelf,
    alignContent,

    flex: flexStyle,
    direction: flexDirection,
    wrap: flexWrap,
    grow: flexGrow,
    shrink: flexShrink,
    basis: flexBasis,

    style,

    ...otherProps
  } = props;

  const containerStyles = useStyles({
    justifyContent: justifyContent ?? undefined,
    alignItems: alignItems ?? undefined,
    alignSelf: alignSelf ?? undefined,
    alignContent: alignContent ?? undefined,

    flexStyle: flexStyle ?? undefined,
    flexDirection: flexDirection ?? undefined,
    flexWrap:
      typeof flexWrap === 'boolean' ? (flexWrap ? 'wrap' : 'nowrap') : flexWrap ? `wrap-${flexWrap}` : undefined,
    flexGrow: flexGrow ?? undefined,
    flexShrink: flexShrink ?? undefined,
    flexBasis: flexBasis ?? undefined,

    ...style,
  } as any);

  return <div {...otherProps} style={containerStyles} ref={externalRef} />;
});

// -------------------------------------------------------------------------- //

export interface FlexItemProps extends Pick<FlexProps, 'grow' | 'shrink' | 'basis' | 'alignSelf'> {}

export const FlexItem = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexProps>(
  'FlexItem',

  (props, externalRef) => {
    const { className, inline, ...otherProps } = props;

    return (
      <FlexLayout
        className={classNames([styles.FlexItem, className, inline && styles.inlineItem])}
        {...otherProps}
        ref={externalRef}
      />
    );
  },
);

// -------------------------------------------------------------------------- //

export const FlexContainer = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexProps>(
  'FlexContainer',

  (props, externalRef) => {
    const { className, inline, ...otherProps } = props;

    return (
      <FlexLayout
        className={classNames([styles.FlexContainer, styles.FlexItem, className, inline && styles.inlineContainer])}
        {...otherProps}
        ref={externalRef}
      />
    );
  },
);

// -------------------------------------------------------------------------- //

interface FlexRowProps extends FlexProps {
  direction?: never;
  reverse?: boolean;
  horizontal?: MainAxisAlignment;
  vertical?: CrossAxisAlignment;
}

export const FlexRow = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexRowProps>('Row', (props, externalRef) => {
  const { reverse, horizontal, vertical, ...otherProps } = props;
  const direction = reverse ? 'row-reverse' : 'row';

  return (
    <FlexContainer
      direction={direction}
      justifyContent={horizontal}
      alignItems={vertical}
      {...otherProps}
      ref={externalRef}
    />
  );
});

// -------------------------------------------------------------------------- //

interface FlexColumnProps extends FlexProps {
  direction?: never;
  reverse?: boolean;
  horizontal?: CrossAxisAlignment;
  vertical?: MainAxisAlignment;
}

export const FlexColumn = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, FlexColumnProps>(
  'Column',
  (props, externalRef) => {
    const { reverse, horizontal, vertical, ...otherProps } = props;
    const direction = reverse ? 'column-reverse' : 'column';

    return (
      <FlexContainer
        direction={direction}
        justifyContent={vertical}
        alignItems={horizontal}
        {...otherProps}
        ref={externalRef}
      />
    );
  },
);
