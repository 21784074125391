import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Spinner: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M13.25 3.25C13.25 2.58594 12.6641 2 12 2C11.2969 2 10.75 2.58594 10.75 3.25C10.75 3.95313 11.2969 4.5 12 4.5C12.6641 4.5 13.25 3.95313 13.25 3.25ZM12 19.5C11.2969 19.5 10.75 20.0859 10.75 20.75C10.75 21.4531 11.2969 22 12 22C12.6641 22 13.25 21.4531 13.25 20.75C13.25 20.0859 12.6641 19.5 12 19.5ZM22 12C22 11.3359 21.4141 10.75 20.75 10.75C20.0469 10.75 19.5 11.3359 19.5 12C19.5 12.7031 20.0469 13.25 20.75 13.25C21.4141 13.25 22 12.7031 22 12ZM4.5 12C4.5 11.3359 3.91406 10.75 3.25 10.75C2.54688 10.75 2 11.3359 2 12C2 12.7031 2.54688 13.25 3.25 13.25C3.91406 13.25 4.5 12.7031 4.5 12ZM5.78906 16.9609C5.08594 16.9609 4.53906 17.5078 4.53906 18.2109C4.53906 18.9141 5.08594 19.4609 5.78906 19.4609C6.49219 19.4609 7.03906 18.9141 7.03906 18.2109C7.03906 17.5078 6.49219 16.9609 5.78906 16.9609ZM18.1719 16.9609C17.4688 16.9609 16.9219 17.5078 16.9219 18.2109C16.9219 18.9141 17.4688 19.4609 18.1719 19.4609C18.875 19.4609 19.4219 18.9141 19.4219 18.2109C19.4219 17.5078 18.875 16.9609 18.1719 16.9609ZM5.78906 4.57813C5.08594 4.57813 4.53906 5.125 4.53906 5.82813C4.53906 6.53125 5.08594 7.07813 5.78906 7.07813C6.49219 7.07813 7.03906 6.53125 7.03906 5.82813C7.03906 5.125 6.49219 4.57813 5.78906 4.57813Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
