import React from 'react';
import { PressEvent } from '@react-types/shared';
import { usePress } from '@react-aria/interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';
import { useScaleIn } from '../../../hooks/transitions';

import styles from './toggle-button.less';

interface ToggleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked?: boolean;
  disabled?: boolean;
  onPress?: (event: PressEvent) => void;
  color?: 'primary' | 'success' | 'warning' | 'error';
}

export const ToggleButton = /* @__PURE__ */ forwardRefWrapper<HTMLButtonElement, ToggleButtonProps>(
  'ToggleButton',

  { color: 'primary' },

  (props, externalRef) => {
    const { onPress, className, disabled, checked, color, ...otherProps } = props;

    const { isFocusVisible, focusProps } = useFocusRing();
    const { pressProps, isPressed } = usePress({ onPress, isDisabled: disabled });
    const getScaleProps = useScaleIn();

    return (
      <button
        className={classNames([
          className,
          styles.ToggleButton,
          styles[`color_${color}`],
          disabled && styles.disabled,
          checked && styles.checked,
          isFocusVisible && styles.focusVisible,
          isPressed && styles.active,
        ])}
        type="button"
        role="switch"
        disabled={disabled}
        aria-disabled={disabled}
        aria-checked={checked}
        tabIndex={0}
        {...mergeProps(pressProps, focusProps, otherProps)}
        ref={externalRef}
      >
        <AnimatePresence>
          {isFocusVisible && <motion.span aria-hidden="true" className={styles.focusRing} {...getScaleProps(1.1)} />}
        </AnimatePresence>
      </button>
    );
  },
);
