/* eslint-disable @typescript-eslint/prefer-regexp-exec */

import { useCallback, useRef } from 'react';
import { useSSR } from '../components/ssr-provider';

/**
 * Copies text to the native clipboard, either via the `navigator.clipboard`
 * API, or old `document.execCommand('copy')` hacks!
 *
 * Based on this excellent StackOverflow answer:
 * https://stackoverflow.com/a/45308151
 */
async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    const textarea = document.createElement('textarea');

    // Move it off-screen.
    textarea.style.position = 'absolute';
    textarea.style.left = '-2147483647em';

    // Set to readonly to prevent mobile devices opening a keyboard when
    // text is .select()'ed.
    textarea.setAttribute('readonly', true as any);

    document.body.appendChild(textarea);

    textarea.value = text;

    // Check if there is any content selected previously.
    const selected = (document.getSelection()?.rangeCount ?? NaN) > 0 ? document.getSelection()?.getRangeAt(0) : false;

    // iOS Safari blocks programmatic execCommand copying normally, without this hack.
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const editable = textarea.contentEditable;
      textarea.contentEditable = true as any;
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textarea.setSelectionRange(0, 999999);
      textarea.contentEditable = editable;
    } else {
      textarea.select();
    }

    document.execCommand('copy');

    // Restore previous selection.
    if (selected) {
      document.getSelection()?.removeAllRanges();
      document.getSelection()?.addRange(selected);
    }
  }
}

interface CopyFn {
  (source?: string): void;
  (source?: Event): void;
  (source?: any): void;
}

interface OnCopyHandler {
  (value: string): void;
}

/**
 * Creates a memoized callback—`copy`—that interacts with the user's computer clipboard.
 * Also provides a `ref` to attach to inputs, which can then be used as a source
 * for the clipboard value.
 */
export function useClipboard(onCopy?: OnCopyHandler) {
  const ref = useRef<HTMLInputElement | null>(null);
  const { isServer } = useSSR();

  const copy = useCallback<CopyFn>(
    async (source?: any) => {
      if (isServer) return;

      const text =
        typeof source === 'string'
          ? String(source)
          : (ref.current as HTMLInputElement)?.value ?? ref.current?.textContent ?? source?.target?.value ?? '';

      await copyToClipboard(text);

      if (onCopy) onCopy(text);
    },
    [onCopy, isServer],
  );

  return { copy, ref };
}
