import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useClickOutside } from 'usable-react';
import { useFocusWithin, useHover, useFocusVisible } from '@react-aria/interactions';
import { Tooltip } from './tooltip/tooltip';
import { mergeProps } from '../../libs/merge-props';
import { useSSR } from '../ssr-provider';

interface AnchoredTooltipProps extends Omit<React.ComponentProps<typeof Tooltip>, 'in'> {
  toggle?: boolean;
  initialToggleValue?: boolean;
}

/**
 * A high-level tooltip component. Under the hood, this component wires up the
 * supplied `anchor` with mouse/touch events to show or hide the tooltip.
 */
export const AnchoredTooltip: React.FC<AnchoredTooltipProps> = (props) => {
  const { toggle, initialToggleValue, anchor: tooltipAnchor, delay, ...otherProps } = props;

  const [focusWithin, setFocusWithinState] = useState(false);
  const [hover, setHoverState] = useState(false);
  const [showTooltip, setShowTooltip] = useState(toggle ? initialToggleValue : false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const { isServer } = useSSR();

  if (!isServer) {
    useClickOutside([tooltipRef.current, anchorRef.current], () => setShowTooltip(false), []);
  }

  const { isFocusVisible } = useFocusVisible();

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: useCallback((isFocused) => setFocusWithinState(isFocused && isFocusVisible), [isFocusVisible]),
  });

  const { hoverProps } = useHover({
    onHoverChange: useCallback((isHovered) => setHoverState(isHovered), []),
  });

  useEffect(() => {
    setShowTooltip(focusWithin || hover);
  }, [focusWithin, hover]);

  const anchor = (
    <div {...mergeProps(hoverProps, focusWithinProps)} ref={anchorRef}>
      {tooltipAnchor}
    </div>
  );

  return <Tooltip in={showTooltip} anchor={anchor} delay={toggle ? 0 : delay} {...otherProps} ref={tooltipRef} />;
};
