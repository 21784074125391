import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const CaretUp: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M11.4698 8.20912C11.7817 7.94001 12.2183 7.94001 12.5302 8.1553L19.7661 14.5064C20.078 14.7755 20.078 15.2061 19.7661 15.4214L19.3294 15.7982C19.0175 16.0673 18.5809 16.0673 18.269 15.7982L12.0312 10.3082L5.73099 15.7982C5.4191 16.0673 4.98246 16.0673 4.67056 15.7982L4.23392 15.4214C3.92203 15.2061 3.92203 14.7755 4.23392 14.5064L11.4698 8.20912Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
