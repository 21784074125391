/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React from 'react';
import { PressEvent } from '@react-types/shared';
import { usePress } from '@react-aria/interactions';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';

import styles from './linkable.less';

interface LinkableProps extends React.HTMLAttributes<HTMLSpanElement> {
  onPress?: (event: PressEvent) => void;
  ambientUnderline?: boolean;
}

export const Linkable = /* @__PURE__ */ forwardRefWrapper<HTMLSpanElement, LinkableProps>(
  'Linkable',

  (props, externalRef) => {
    const { children, onPress, ambientUnderline, className, ...otherProps } = props;

    const isTextChild = typeof children === 'string' || typeof children === 'number';

    const { pressProps } = usePress({ onPress });

    return (
      <span
        className={classNames([
          className,
          styles.Linkable,
          ambientUnderline && styles.ambientUnderline,
          isTextChild && styles.isTextChild,
        ])}
        role={isTextChild ? 'link' : undefined}
        tabIndex={isTextChild ? 0 : -1}
        {...mergeProps(onPress ? pressProps : {}, otherProps)}
        ref={externalRef as any}
      >
        {children}
      </span>
    );
  },
);
