import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Browser: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M39.375 16H16.625C15.1484 16 14 17.1786 14 18.5714V37.4286C14 38.875 15.1484 40 16.625 40H39.375C40.7969 40 42 38.875 42 37.4286V18.5714C42 17.1786 40.7969 16 39.375 16ZM15.75 18.5714C15.75 18.1429 16.1328 17.7143 16.625 17.7143H19.25V21.1429H15.75V18.5714ZM40.25 21.1429H21V17.7143H39.375C39.8125 17.7143 40.25 18.1429 40.25 18.5714V21.1429Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => theme.hex.primary.darkest,
  colorB: (theme) => theme.hex.primary.lightest,
};
