import { createMedia } from './create-media';
import { Theme } from './theme-types';

type ThemeVariable =
  | '--mg__primary'
  | '--mg__primary_lighter'
  | '--mg__primary_lightest'
  | '--mg__primary_darker'
  | '--mg__primary_darkest'
  | '--mg__secondary'
  | '--mg__secondary_lighter'
  | '--mg__secondary_lightest'
  | '--mg__secondary_darker'
  | '--mg__secondary_darkest'
  | '--mg__tertiary'
  | '--mg__tertiary_lighter'
  | '--mg__tertiary_lightest'
  | '--mg__tertiary_darker'
  | '--mg__tertiary_darkest'
  | '--mg__success'
  | '--mg__success_lighter'
  | '--mg__success_lightest'
  | '--mg__success_darker'
  | '--mg__success_darkest'
  | '--mg__warning'
  | '--mg__warning_lighter'
  | '--mg__warning_lightest'
  | '--mg__warning_darker'
  | '--mg__warning_darkest'
  | '--mg__error'
  | '--mg__error_lighter'
  | '--mg__error_lightest'
  | '--mg__error_darker'
  | '--mg__error_darkest'
  | '--mg__light'
  | '--mg__light_lighter'
  | '--mg__light_lightest'
  | '--mg__light_darker'
  | '--mg__light_darkest'
  | '--mg__dark'
  | '--mg__dark_lighter'
  | '--mg__dark_lightest'
  | '--mg__dark_darker'
  | '--mg__dark_darkest'
  | '--mg__mid'
  | '--mg__mid_lighter'
  | '--mg__mid_lightest'
  | '--mg__mid_darker'
  | '--mg__mid_darkest'
  | '--mg__font_sansSerif'
  | '--mg__font_monospace';

export function getThemeVariables(theme: Theme) {
  const createCSSVariableGroup = <T extends keyof typeof theme['rgba']>(name: T, rgba: typeof theme['rgba'][T]) =>
    ({
      [`--mg__${name}`]: rgba.base.array().slice(0, 3).join(', '),
      [`--mg__${name}_lighter`]: rgba.lighter.array().slice(0, 3).join(', '),
      [`--mg__${name}_lightest`]: rgba.lightest.array().slice(0, 3).join(', '),
      [`--mg__${name}_darker`]: rgba.darker.array().slice(0, 3).join(', '),
      [`--mg__${name}_darkest`]: rgba.darkest.array().slice(0, 3).join(', '),
    } as any);

  const variables: Record<ThemeVariable, string> = {
    ...createCSSVariableGroup('primary', theme.rgba.primary),
    ...createCSSVariableGroup('secondary', theme.rgba.secondary),
    ...createCSSVariableGroup('tertiary', theme.rgba.tertiary),
    ...createCSSVariableGroup('success', theme.rgba.success),
    ...createCSSVariableGroup('warning', theme.rgba.warning),
    ...createCSSVariableGroup('error', theme.rgba.error),
    ...createCSSVariableGroup('light', theme.rgba.light),
    ...createCSSVariableGroup('dark', theme.rgba.dark),
    ...createCSSVariableGroup('mid', theme.rgba.mid),
    '--mg__font_sansSerif': theme.font.sansSerif,
    '--mg__font_monospace': theme.font.monospace,
  };

  return {
    variables,

    toCSS() {
      /* eslint-disable prettier/prettier */

      // Map theme variables to `:root` CSS block
      const cssVars = Object.keys(variables).map((property) => `  ${property}:${variables[property]};`).join('\n');
      const cssVarsFormatted = ['/* [@magiclabs/ui] Root CSS Variables */', ':root {', cssVars, '}'].join('\n');

      // Convert media queries to CSS (via `@artsy/fresnel`)
      const mediaStyles = createMedia(theme.breakpoints).createMediaStyle();
      const mediaStylesFormatted = ['/* [@artsy/fresnel] Media Queries */', mediaStyles].join('\n');

      /* eslint-enable prettier/prettier */

      // Put it all together with nice formatting!
      return [cssVarsFormatted, mediaStylesFormatted].join('\n\n');
    },

    applyToDocument() {
      // For each CSS variable, we'll map it to the current `Document`.
      for (const property of Object.keys(variables)) {
        document.documentElement.style.setProperty(property, variables[property]);
      }

      // FYI: We don't need to do anything with media queries from `@artsy/fresnel`
      // here, those are handled client-side by that package internally.
    },
  };
}
