import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Envelope: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M20.125 4H3.875C2.82031 4 2 4.91667 2 6V18C2 19.125 2.82031 20 3.875 20H20.125C21.1406 20 22 19.125 22 18V6C22 4.91667 21.1406 4 20.125 4ZM3.875 5.33333H20.125C20.4375 5.33333 20.75 5.66667 20.75 6V7.75C19.8906 8.5 18.6406 9.58333 14.8516 12.7917C14.1875 13.375 12.8984 14.7083 12 14.6667C11.0625 14.7083 9.77344 13.375 9.10938 12.7917C5.32031 9.58333 4.07031 8.5 3.25 7.75V6C3.25 5.66667 3.52344 5.33333 3.875 5.33333ZM20.125 18.6667H3.875C3.52344 18.6667 3.25 18.375 3.25 18V9.45833C4.10938 10.25 5.51563 11.4583 8.32813 13.8333C9.14844 14.5417 10.5547 16.0417 12 16C13.4063 16.0417 14.8125 14.5417 15.6328 13.8333C18.4453 11.4583 19.8516 10.25 20.75 9.45833V18C20.75 18.375 20.4375 18.6667 20.125 18.6667Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
