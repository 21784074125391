import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Edit: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M16.934 14.34C16.8958 14.3791 16.8958 14.4573 16.8958 14.4963V20.1241C16.8958 20.4758 16.5901 20.7494 16.2844 20.7494H2.83413C2.49023 20.7494 2.22275 20.4758 2.22275 20.1241V6.36737C2.22275 6.05471 2.49023 5.74206 2.83413 5.74206H13.2275C13.2657 5.74206 13.3422 5.74206 13.3804 5.70298L14.1446 4.92135C14.2974 4.76502 14.1828 4.49145 13.9917 4.49145H2.83413C1.80243 4.49145 1 5.35125 1 6.36737V20.1241C1 21.1793 1.80243 22 2.83413 22H16.2844C17.2779 22 18.1185 21.1793 18.1185 20.1241V13.7147C18.1185 13.5193 17.8511 13.4021 17.6982 13.5584L16.934 14.34ZM22.5128 6.87543C23.1624 6.21104 23.1624 5.19492 22.5128 4.53053L20.5258 2.49829C19.8762 1.8339 18.8828 1.8339 18.2332 2.49829L6.31133 14.6917L5.89101 18.5999C5.81459 19.147 6.23491 19.5769 6.76987 19.4988L10.591 19.0689L22.5128 6.87543ZM18.9592 8.75134L10.0178 17.8964L7.15198 18.2091L7.45766 15.278L16.399 6.13288L18.9592 8.75134ZM21.6722 5.42941C21.825 5.58574 21.825 5.82023 21.6722 5.97655L19.7998 7.85247L17.2779 5.27308L19.112 3.35809C19.2649 3.20176 19.4941 3.24084 19.647 3.35809L21.6722 5.42941Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
