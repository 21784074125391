import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Add: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M19.6667 11H13V4.33333C13 4.16667 12.8333 4 12.6667 4H11.3333C11.125 4 11 4.16667 11 4.33333V11H4.33333C4.125 11 4 11.1667 4 11.3333V12.6667C4 12.875 4.125 13 4.33333 13H11V19.6667C11 19.875 11.125 20 11.3333 20H12.6667C12.8333 20 13 19.875 13 19.6667V13H19.6667C19.8333 13 20 12.875 20 12.6667V11.3333C20 11.1667 19.8333 11 19.6667 11Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
