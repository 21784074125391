import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { useIsMounted } from 'usable-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ToastContext, ToastDefinition } from '../use-toast';
import { EphemeralPortal, Inset, FlexRow } from '../../../layout';
import { ThemeProvider } from '../../../theme';
import { useTheme } from '../../../theme/use-theme';
import { classNames } from '../../../../libs/class-names';
import { useSlide } from '../../../../hooks/transitions';
import { Icon, MonochromeIcons } from '../../../icons';

import styles from './toast-provider.less';

let toastID = 0;

const toastIcons = {
  success: () => MonochromeIcons.SuccessFilled,
  warning: () => MonochromeIcons.Warning,
  error: () => MonochromeIcons.Exclaim,
  info: () => MonochromeIcons.Info,
};

interface ToastProviderProps {
  position?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';
}

export const ToastProvider: React.FC<ToastProviderProps> = (props) => {
  const { position, children } = props;

  const isMounted = useIsMounted();
  const theme = useTheme();
  const toastsRef = useRef<ToastDefinition[]>([]);
  const forceUpdate = useReducer((x: number) => x + 1, 0)[1];

  const removeToast: ToastContext['removeToast'] = useCallback((id: number) => {
    toastsRef.current = toastsRef.current.filter((toast) => toast.id !== id);
    forceUpdate();
  }, []);

  const createToast: ToastContext['createToast'] = useCallback((options) => {
    const { message, messageOnly, type = 'info', lifespan = 2000 } = options;
    const id = ++toastID;

    toastsRef.current.push({ message, messageOnly, type, id });
    forceUpdate();

    setTimeout(() => {
      if (isMounted()) removeToast(id);
    }, lifespan);

    return id;
  }, []);

  const toastCtx = useMemo(() => ({ createToast, removeToast }), [createToast, removeToast]);
  const getSlideProps = useSlide();
  const toasts = position?.startsWith('top') ? [...toastsRef.current] : [...toastsRef.current].reverse();
  const activeToasts = toasts.map((toast) => {
    return (
      <motion.div
        layout
        className={classNames([styles.toast, styles[toast.type]])}
        data-mg-toast-id={toast.id}
        key={toast.id}
        {...getSlideProps({ x: 0, y: position?.startsWith('top') ? -10 : 10 })}
      >
        <FlexRow vertical="flex-start">
          {!toast.messageOnly && (
            <Inset top={2}>
              <Icon inline={false} size={20} type={toastIcons[toast.type]()} />
            </Inset>
          )}

          <div role="alert" className={styles.message}>
            {toast.message}
          </div>
        </FlexRow>
      </motion.div>
    );
  });

  return (
    <ToastContext.Provider value={toastCtx}>
      {children}

      <EphemeralPortal>
        <ThemeProvider theme={theme}>
          <Inset all={40}>
            <div className={styles.ToastNotifications}>
              <div className={classNames([styles.toastContainer, styles[`position_${position}`]])}>
                <AnimatePresence>{activeToasts}</AnimatePresence>
              </div>
            </div>
          </Inset>
        </ThemeProvider>
      </EphemeralPortal>
    </ToastContext.Provider>
  );
};

ToastProvider.defaultProps = {
  position: 'top',
};
