import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const LaptopWithCode: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M24.75 28.3047C25.05 28.6094 25.55 28.6094 25.85 28.3047L26.4 27.7461C26.75 27.4414 26.75 26.8828 26.4 26.5781L24.65 24.75L26.4 22.9727C26.75 22.668 26.75 22.1094 26.4 21.8047L25.85 21.2461C25.55 20.9414 25.05 20.9414 24.75 21.2461L21.8 24.1914C21.5 24.4961 21.5 25.0547 21.8 25.3594L24.75 28.3047ZM29.55 27.7461L30.1 28.3047C30.4 28.6094 30.9 28.6094 31.2 28.3047L34.15 25.3594C34.45 25.0547 34.45 24.4961 34.15 24.1914L31.2 21.2461C30.9 20.9414 30.4 20.9414 30.1 21.2461L29.55 21.8047C29.2 22.1094 29.2 22.668 29.55 22.9727L31.3 24.75L29.55 26.5781C29.2 26.8828 29.2 27.4414 29.55 27.7461ZM43.2 36.125H31.05C31 37.1406 30.3 37.75 29.4 37.75H26.4C25.45 37.75 24.7 36.8867 24.75 36.125H12.8C12.35 36.125 12 36.5312 12 36.9375V37.75C12 39.5781 13.4 41 15.2 41H40.8C42.55 41 44 39.5781 44 37.75V36.9375C44 36.5312 43.6 36.125 43.2 36.125ZM40.8 17.4375C40.8 16.1172 39.7 15 38.4 15H17.6C16.25 15 15.2 16.1172 15.2 17.4375V34.5H40.8V17.4375ZM37.6 31.25H18.4V18.25H37.6V31.25Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.mid.darkest : theme.hex.mid.lightest),
  colorB: (theme) => (theme.isLightTheme ? theme.hex.mid.lightest : theme.hex.mid.darkest),
};
