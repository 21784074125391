import React from 'react';
// import { classNames } from '../../../libs/class-names';
// import { useTabs } from './tabs';

export interface TabPanelProps {
  id: string;
  tabID: string;
  selected: boolean;
}

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { id, tabID, selected, children } = props;

  return (
    <div id={id} role="tabpanel" aria-labelledby={tabID} tabIndex={0} style={{ display: selected ? 'block' : 'none' }}>
      {selected ? children : null}
    </div>
  );
};
