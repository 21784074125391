import React, { createContext, useContext, useMemo } from 'react';
import { Theme } from '../../libs/theme';
import { useTheme } from '../theme/use-theme';
import { classNames } from '../../libs/class-names';
import { forwardRefWrapper } from '../../libs/forward-ref-wrapper';

import styles from './icon-base.less';

// --- Shared icon context -------------------------------------------------- //

interface IconColorContext {
  colorA: string;
  colorB: string;
  opacityA: number;
  opacityB: number;
}

const defaultIconColorContext: IconColorContext = {
  colorA: 'var(--mg__Icon_colorA)',
  colorB: 'var(--mg__Icon_colorB)',
  opacityA: 1,
  opacityB: 1,
};

const IconColorContext = /* @__PURE__ */ createContext<IconColorContext>(defaultIconColorContext);

/**
 * Creates a React context that overrides the fallback
 * color properties of icons rendered within.
 */
export const IconColorProvider: React.FC<Partial<IconColorContext>> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <IconColorContext.Provider value={{ ...defaultIconColorContext, ...otherProps }}>
      {children}
    </IconColorContext.Provider>
  );
};

// --- Monochrome Icons ----------------------------------------------------- //

type MonochromeSVGContents = React.FC<{
  fill: { fill: string; fillOpacity: number };
  stroke: { stroke: string; strokeOpacity: number };
  theme: Theme;
}>;

export interface MonochromeIconDefinition {
  SVGContents: MonochromeSVGContents;
  viewbox: [number, number, number, number];
  color?: string | ((theme: Theme) => string);
  opacity?: number | ((theme: Theme) => number);
}

interface MonochromeColor {
  color?: string;
  opacity?: number;
}

interface MonochromeIconBaseProps extends MonochromeColor, React.HTMLAttributes<HTMLSpanElement> {
  type: MonochromeIconDefinition;
  size?: number | { width?: number; height?: number };
  disabled?: boolean;
  inline?: boolean;
}

export const Icon = /* @__PURE__ */ forwardRefWrapper<HTMLSpanElement, MonochromeIconBaseProps>(
  'Icon',

  { inline: true },

  (props, externalRef) => {
    const { type, color, opacity, size, disabled, inline, className, ...otherProps } = props;

    const theme = useTheme();

    const [viewboxX, viewboxY, viewboxWidth, viewboxHeight] = type.viewbox;
    const propWidth = typeof size === 'number' ? size : size?.width;
    const propHeight = typeof size === 'number' ? size : size?.height;
    const { width, height } = useAspectRatio(type.viewbox, propWidth, propHeight);

    // --- Color/opacity values

    const currentColor = color ?? useDefaultValue(theme, type.color) ?? useContext(IconColorContext).colorA;
    const currentOpacity = opacity ?? useDefaultValue(theme, type.opacity) ?? useContext(IconColorContext).opacityA;

    return (
      <span
        className={classNames([styles.IconBase, inline && styles.inline, className])}
        aria-hidden="true"
        {...otherProps}
        ref={externalRef}
      >
        <svg
          width={typeof size === 'number' ? size : width}
          height={typeof size === 'number' ? size : height}
          viewBox={`${viewboxX} ${viewboxY} ${viewboxWidth} ${viewboxHeight}`}
          fill="none"
          stroke="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <type.SVGContents
            fill={useSVGProps('fill', currentColor, currentOpacity)}
            stroke={useSVGProps('stroke', currentColor, currentOpacity)}
            theme={theme}
          />
        </svg>
      </span>
    );
  },
);

// --- Duotone Icons -------------------------------------------------------- //

type DuotoneSVGContents = React.FC<{
  fillA: { fill: string; fillOpacity: number };
  fillB: { fill: string; fillOpacity: number };
  strokeA: { stroke: string; strokeOpacity: number };
  strokeB: { stroke: string; strokeOpacity: number };
  theme: Theme;
}>;

export interface DuotoneIconDefinition {
  SVGContents: DuotoneSVGContents;
  viewbox: [number, number, number, number];
  colorA?: string | ((theme: Theme) => string);
  colorB?: string | ((theme: Theme) => string);
  opacityA?: number | ((theme: Theme) => number);
  opacityB?: number | ((theme: Theme) => number);
}

interface DuotoneColor {
  colorA?: string;
  colorB?: string;
  opacityA?: number;
  opacityB?: number;
}

interface DuotoneIconBaseProps extends DuotoneColor, React.HTMLAttributes<HTMLSpanElement> {
  type: DuotoneIconDefinition;
  size?: number | { width?: number; height?: number };
  disabled?: boolean;
  inline?: boolean;
}

export const DuotoneIcon = /* @__PURE__ */ forwardRefWrapper<HTMLSpanElement, DuotoneIconBaseProps>(
  'DuotoneIcon',

  { inline: true },

  (props, externalRef) => {
    const { type, colorA, colorB, opacityA, opacityB, size, disabled, inline, className, ...otherProps } = props;

    const theme = useTheme();

    const [viewboxX, viewboxY, viewboxWidth, viewboxHeight] = type.viewbox;
    const propWidth = typeof size === 'number' ? size : size?.width;
    const propHeight = typeof size === 'number' ? size : size?.height;
    const { width, height } = useAspectRatio(type.viewbox, propWidth, propHeight);

    // --- "A" color/opacity values

    const currentColorA = colorA ?? useDefaultValue(theme, type.colorA) ?? useContext(IconColorContext).colorA;
    const currentOpacityA = opacityA ?? useDefaultValue(theme, type.opacityA) ?? useContext(IconColorContext).opacityA;

    // --- "B" color/opacity values

    const currentColorB = colorB ?? useDefaultValue(theme, type.colorB) ?? useContext(IconColorContext).colorB;
    const currentOpacityB = opacityB ?? useDefaultValue(theme, type.opacityB) ?? useContext(IconColorContext).opacityB;

    return (
      <span
        className={classNames([styles.IconBase, inline && styles.inline, className])}
        aria-hidden="true"
        {...otherProps}
        ref={externalRef}
      >
        <svg
          width={typeof size === 'number' ? size : width}
          height={typeof size === 'number' ? size : height}
          viewBox={`${viewboxX} ${viewboxY} ${viewboxWidth} ${viewboxHeight}`}
          fill="none"
          stroke="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <type.SVGContents
            fillA={useSVGProps('fill', currentColorA, currentOpacityA)}
            fillB={useSVGProps('fill', currentColorB, currentOpacityB)}
            strokeA={useSVGProps('stroke', currentColorA, currentOpacityA)}
            strokeB={useSVGProps('stroke', currentColorB, currentOpacityB)}
            theme={theme}
          />
        </svg>
      </span>
    );
  },
);

// --- Utilities ------------------------------------------------------------ //

/**
 * Returns a memoized value representing
 * the default icon color or opacity.
 */
function useDefaultValue<T>(theme: Theme | null, resolver: T | ((theme: Theme) => T)): T {
  return useMemo(() => (typeof resolver === 'function' ? (resolver as any)(theme) : resolver), [theme?.key]);
}

/**
 * Returns memoized SVG props for `stroke` or `fill` properties.
 */
function useSVGProps(property: 'fill', color: string, opacity: number): { fill: string; fillOpacity: number };
function useSVGProps(property: 'stroke', color: string, opacity: number): { stroke: string; strokeOpacity: number };
function useSVGProps(property: 'stroke' | 'fill', color: string, opacity: number): any {
  return useMemo<any>(() => ({ [property]: color, [`${property}Opacity`]: opacity }), [color, opacity]);
}

/**
 * From the given `viewbox`, calculates a
 * width and height value that respects the
 * icon's aspect ratio.
 */
function useAspectRatio(viewbox: [number, number, number, number], propWidth?: number, propHeight?: number) {
  const viewboxWidth = viewbox[2];
  const viewboxHeight = viewbox[3];

  let width = Math.ceil(propWidth ?? viewboxWidth);
  let height = Math.ceil(propHeight ?? viewboxHeight);
  const aspectRatio = viewboxWidth / viewboxHeight;

  if (!!propWidth && !propHeight) height = Math.ceil(width / aspectRatio);
  if (!!propHeight && !propWidth) width = Math.ceil(height * aspectRatio);

  return { width, height };
}
