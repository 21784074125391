import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Refresh: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M39.4286 16H38.0953C37.7619 16 37.5238 16.2903 37.5238 16.629L37.6191 21.371C35.5239 18.129 31.8573 16 27.7621 16.0484C21.2383 16.0484 15.9527 21.4677 16.0003 28.0484C16.0003 34.6774 21.286 40 27.8097 40C30.8096 40 33.6191 38.8387 35.7143 36.9516C35.9524 36.7097 35.9524 36.3226 35.7143 36.0806L34.762 35.1129C34.5715 34.9194 34.2382 34.9194 34.0001 35.1129C32.3334 36.6129 30.1906 37.4839 27.8097 37.4839C22.6193 37.4839 18.4765 33.2742 18.4765 28C18.4765 22.7742 22.6193 18.5161 27.8097 18.5161C31.5715 18.5161 34.8096 20.8387 36.2858 24.129L30.2858 23.9839C29.9525 23.9839 29.7144 24.2258 29.7144 24.5645V25.871C29.7144 26.2097 29.9525 26.4516 30.2858 26.4516H39.4286C39.7143 26.4516 40 26.2097 40 25.871V16.5806C40 16.2903 39.7143 16 39.4286 16Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.mid.darkest : theme.hex.mid.lightest),
  colorB: (theme) => (theme.isLightTheme ? theme.hex.mid.lightest : theme.hex.mid.darkest),
};
