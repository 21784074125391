import React, { useState, useRef, useEffect } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import { usePress } from '@react-aria/interactions';
import { Input } from './input/input';
import { Icon, MonochromeIcons } from '../icons';
import { AnchoredTooltip } from '../feedback';
import { useClipboard } from '../../hooks/use-clipboard';
import { forwardRefWrapper } from '../../libs/forward-ref-wrapper';
import { TextButton } from '.';
import { mergeProps } from '../../libs/merge-props';

interface CopyInputProps extends React.ComponentProps<typeof Input> {
  onCopy?: () => void;
}

export const CopyableInput = /* @__PURE__ */ forwardRefWrapper<HTMLInputElement, CopyInputProps>(
  'CopyableInput',

  (props, externalRef) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [copyTooltipText, setCopyTooltipText] = useState('Copy');

    const { value, defaultValue, onCopy, size, ...otherProps } = props;

    useEffect(() => {
      if (copyTooltipText !== 'Copy') {
        const timeout = setTimeout(() => setCopyTooltipText('Copy'), 1000);
        return () => clearTimeout(timeout);
      }

      return undefined;
    }, [copyTooltipText]);

    const { copy, ref: clipboardRef } = useClipboard(() => {
      setCopyTooltipText('Copied!');
      if (onCopy) onCopy();
    });

    const { pressProps } = usePress({ onPress: copy });

    const suffix = (
      <AnchoredTooltip
        toggle
        delay={0}
        anchor={
          <TextButton size={size === 'lg' ? 'md' : size} {...mergeProps(pressProps)}>
            <Icon type={MonochromeIcons.Copy} />
          </TextButton>
        }
      >
        <p style={{ textAlign: 'center' }}>{copyTooltipText}</p>
      </AnchoredTooltip>
    );

    return (
      <Input
        value={value}
        defaultValue={defaultValue}
        suffix={suffix}
        size={size}
        {...otherProps}
        ref={composeRefs(inputRef, externalRef, clipboardRef)}
      />
    );
  },
);
