/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useRef, createContext, useContext } from 'react';
import { PressEvent } from '@react-types/shared';
import { usePress, useHover } from '@react-aria/interactions';
import composeRefs from '@seznam/compose-react-refs';
import { AnimatePresence, motion } from 'framer-motion';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { Spacer } from '../../layout';
import { useScale, useScaleIn } from '../../../hooks/transitions';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';

import styles from './radio.less';

const RadioGroupContext = /* @__PURE__ */ createContext<any>({});

interface RadioGroupProps {
  name: string;
  selectedValue?: string | number | boolean;
  onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { name, selectedValue, onChange, children } = props;

  return <RadioGroupContext.Provider value={{ name, selectedValue, onChange }}>{children}</RadioGroupContext.Provider>;
};

RadioGroup.defaultProps = {
  onChange: () => {},
};

RadioGroup.displayName = 'RadioGroup';

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  color?: 'primary' | 'success' | 'warning' | 'error';
  onPress?: (event: PressEvent) => void;
}

export const Radio = /* @__PURE__ */ forwardRefWrapper<HTMLInputElement, RadioProps>(
  'Radio',

  { color: 'primary' },

  (props, externalRef) => {
    const { onPress, disabled, checked, label, color, value, ...otherProps } = props;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { pressProps, isPressed } = usePress({ onPress, isDisabled: disabled });
    const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
    const getScaleInProps = useScaleIn();
    const getScaleProps = useScale();

    const { name, selectedValue, onChange } = useContext(RadioGroupContext);

    const isChecked =
      checked ||
      (Array.isArray(value)
        ? value.every((i) => i === selectedValue)
        : selectedValue === value && typeof value !== 'undefined' && value !== null);

    return (
      <label className={styles.Radio}>
        <input
          type="radio"
          disabled={disabled}
          aria-disabled={disabled}
          tabIndex={0}
          value={value}
          checked={isChecked}
          {...mergeProps({ name, onChange }, pressProps, focusProps, hoverProps, otherProps)}
          ref={composeRefs(inputRef, externalRef)}
        />

        <span
          className={classNames([
            styles.radiomark,
            styles[`color_${color}`],
            disabled && styles.disabled,
            isChecked && styles.checked,
            isFocusVisible && styles.focusVisible,
            isPressed && styles.active,
            isHovered && styles.hover,
          ])}
          aria-hidden="true"
        >
          <AnimatePresence>
            {isFocusVisible && (
              <motion.span aria-hidden="true" className={styles.focusRing} {...getScaleInProps(1.1)} />
            )}
          </AnimatePresence>

          <AnimatePresence initial={false}>
            {isChecked && (
              <motion.span className={styles.radiomarkIcon} {...getScaleProps(0.7)} key={0}>
                <span />
              </motion.span>
            )}
          </AnimatePresence>
        </span>

        {label && (
          <>
            <Spacer size={8} inline />
            <span>{label}</span>
          </>
        )}
      </label>
    );
  },
);
