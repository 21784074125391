import Color from 'color';
import md5 from 'blueimp-md5';
import { Breakpoint, BreakpointConfig, ThemeType } from './theme-types';

type ColorParam = Color | string | ArrayLike<number> | number | { [key: string]: any };

interface RgbaResolver {
  string(alpha?: string | number): string;
  array(alpha?: string | number): [string, string, string, string]; // [R, G, B, A]
}

// @see https://github.com/Qix-/color/issues/53
function lightenBy(color: Color, ratio: number) {
  const lightness = color.lightness();
  /* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */
  return color.lightness(lightness + (100 - lightness) * ratio);
}

// @see https://github.com/Qix-/color/issues/53
function darkenBy(color: Color, ratio: number) {
  const lightness = color.lightness();
  return color.lightness(lightness - lightness * ratio);
}

/**
 * Creates an object of resolver functions for the given RGBA color name and
 * color representation.
 */
function createRgbaColorResolvers(colorRepresentation: ColorParam): RgbaResolver {
  const color = Color(colorRepresentation);

  return {
    string: (alpha = 1) => {
      const rgbString = color.rgb().array().join(', ');
      return `rgba(${rgbString}, ${alpha})`;
    },

    array: (alpha = 1) =>
      [
        ...color
          .rgb()
          .array()
          .map((val) => String(val)),
        String(alpha),
      ] as [string, string, string, string],
  };
}

type ColorDefinition =
  | string
  | {
      base: string;
      lighter: string;
      lightest: string;
      darker: string;
      darkest: string;
    };

interface ThemeConfig {
  // Colors
  primaryColor: ColorDefinition;
  lightColor: ColorDefinition;
  darkColor: ColorDefinition;
  midColor: ColorDefinition;
  successColor: ColorDefinition;
  warningColor: ColorDefinition;
  errorColor: ColorDefinition;

  // Misc.
  shift?: [number, number];
  type?: ThemeType;
  font?: { sansSerif?: string; monospace?: string };
  breakpoints?: Record<Exclude<Breakpoint, 'xs'>, number>;

  /**
   * There are some design cases where `{color}_lighter` / `{color}_darker`
   * semantics should be reversed. When this option is true, `{color}_lighter`
   * becomes `{color}_darker` and vice versa.
   *
   * Use with care.
   */
  inverseSemantics?: boolean;
}

/**
 * Return a partial `ThemeContext` object with `secondaryColor` and `tertiary`
 * set for greatest contrast.
 */
export function createTheme<T extends ThemeConfig>(config: T) {
  // Theme type ("dark", "light", or "auto").
  const themeType = config.type ?? 'auto';

  // Color values
  const primaryColor = Color(typeof config.primaryColor === 'string' ? config.primaryColor : config.primaryColor.base);
  const lightColor = Color(typeof config.lightColor === 'string' ? config.lightColor : config.lightColor.base);
  const darkColor = Color(typeof config.darkColor === 'string' ? config.darkColor : config.darkColor.base);
  const midColor = Color(typeof config.midColor === 'string' ? config.midColor : config.midColor.base);
  const successColor = Color(typeof config.successColor === 'string' ? config.successColor : config.successColor.base);
  const warningColor = Color(typeof config.warningColor === 'string' ? config.warningColor : config.warningColor.base);
  const errorColor = Color(typeof config.errorColor === 'string' ? config.errorColor : config.errorColor.base);

  // Font values
  const sansSerif =
    config.font?.sansSerif ??
    'ProximaNova, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';
  const monospace = config.font?.monospace ?? 'monospace';

  // Responsive values
  const breakpoints: BreakpointConfig = config.breakpoints
    ? { xs: 0, ...config.breakpoints }
    : { xs: 0, sm: 768, md: 1024, lg: 1216, xl: 1408 };

  // Determine secondary/tertiary colors with best contrast
  const isDarkTheme =
    /* eslint-disable-next-line no-nested-ternary */
    themeType === 'dark' ? true : themeType === 'light' ? false : !primaryColor.isDark();
  const isLowContrast = isDarkTheme !== !primaryColor.isDark();
  const secondaryColor = isDarkTheme ? darkColor : lightColor;
  const tertiaryColor = isDarkTheme ? lightColor : darkColor;

  // Determine how far to shift the color value for 'Darker/Lighter' variants.
  // Stronger contrast === larger shift
  const [colorValueShift, colorValueShiftFurther] = config.shift ?? [0.5, 0.75];

  // --- Hex values

  const createHexGroup = (base: Color, def: ColorDefinition) => {
    const withDefaultSemantics = {
      base: base.hex(),
      lighter: typeof def === 'string' ? lightenBy(base, colorValueShift).hex() : Color(def.lighter).hex(),
      lightest: typeof def === 'string' ? lightenBy(base, colorValueShiftFurther).hex() : Color(def.lightest).hex(),
      darker: typeof def === 'string' ? darkenBy(base, colorValueShift).hex() : Color(def.darker).hex(),
      darkest: typeof def === 'string' ? darkenBy(base, colorValueShiftFurther).hex() : Color(def.darkest).hex(),
    };

    //   `darker` -> `lighter`
    // `lightest` -> `darkest`
    // ...and so forth
    const withInverseSemantics = {
      base: withDefaultSemantics.base,
      lighter: withDefaultSemantics.darker,
      lightest: withDefaultSemantics.darkest,
      darker: withDefaultSemantics.lighter,
      darkest: withDefaultSemantics.lightest,
    };

    return config.inverseSemantics ? withInverseSemantics : withDefaultSemantics;
  };

  const hexResult = {
    primary: createHexGroup(primaryColor, config.primaryColor),
    secondary: createHexGroup(secondaryColor, isDarkTheme ? config.darkColor : config.lightColor),
    tertiary: createHexGroup(tertiaryColor, isDarkTheme ? config.lightColor : config.darkColor),
    success: createHexGroup(successColor, config.successColor),
    warning: createHexGroup(warningColor, config.warningColor),
    error: createHexGroup(errorColor, config.errorColor),
    light: createHexGroup(lightColor, config.lightColor),
    dark: createHexGroup(darkColor, config.darkColor),
    mid: createHexGroup(midColor, config.midColor),
  };

  // --- RGBA values

  const createRgbaGroup = <T extends keyof typeof hexResult>(hex: typeof hexResult[T]) => ({
    base: createRgbaColorResolvers(hex.base),
    lighter: createRgbaColorResolvers(hex.lighter),
    lightest: createRgbaColorResolvers(hex.lightest),
    darker: createRgbaColorResolvers(hex.darker),
    darkest: createRgbaColorResolvers(hex.darkest),
  });

  const rgbaResult = {
    primary: createRgbaGroup(hexResult.primary),
    secondary: createRgbaGroup(hexResult.secondary),
    tertiary: createRgbaGroup(hexResult.tertiary),
    success: createRgbaGroup(hexResult.success),
    warning: createRgbaGroup(hexResult.warning),
    error: createRgbaGroup(hexResult.error),
    light: createRgbaGroup(hexResult.light),
    dark: createRgbaGroup(hexResult.dark),
    mid: createRgbaGroup(hexResult.mid),
  };

  // --- Color instance values

  const createColorGroup = <T extends keyof typeof hexResult>(hex: typeof hexResult[T]) => ({
    base: Color(hex.base),
    lighter: Color(hex.lighter),
    lightest: Color(hex.lightest),
    darker: Color(hex.darker),
    darkest: Color(hex.darkest),
  });

  const colorResult = {
    primary: createColorGroup(hexResult.primary),
    secondary: createColorGroup(hexResult.secondary),
    tertiary: createColorGroup(hexResult.tertiary),
    success: createColorGroup(hexResult.success),
    warning: createColorGroup(hexResult.warning),
    error: createColorGroup(hexResult.error),
    light: createColorGroup(hexResult.light),
    dark: createColorGroup(hexResult.dark),
    mid: createColorGroup(hexResult.mid),
  };

  // --- Create a hash of the theme to track changes

  let key = Object.keys(hexResult).reduce((p1, c1) => {
    return `${p1}:${Object.keys(hexResult[c1]).reduce((p2, c2) => {
      return `${p2}:${hexResult[c1][c2]}`;
    }, c1)}`;
  }, 'theme');

  key += isDarkTheme ? ':dark' : ':light';
  key += isLowContrast ? ':lowcontrast' : '';
  key += `:${sansSerif}:${monospace}`;
  key += `:${config.inverseSemantics ? 'inverse_semantics' : 'default_semantics'}`;
  key += Object.keys(breakpoints).reduce((a, b) => `${a}:${b}`, 'breakpoints');

  key = md5(key);

  // ---

  return {
    isDarkTheme,
    isLightTheme: !isDarkTheme,
    isLowContrast,
    hex: hexResult,
    rgba: rgbaResult,
    color: colorResult,
    font: { sansSerif, monospace },
    breakpoints,
    key,
    config,
  };
}
