import React, { createContext, useContext, useMemo } from 'react';
import { createMedia, CreateMediaResult } from '../../libs/theme/create-media';
import { DEFAULT_THEME } from '../../libs/theme/default-theme';
import { useTheme } from './use-theme';

const WrappedMediaContext = /* @__PURE__ */ createContext<CreateMediaResult>(
  /* @__PURE__ */ createMedia(DEFAULT_THEME.breakpoints),
);

export const MediaProvider: React.FC = (props) => {
  const { children } = props;

  const theme = useTheme();
  const media = useMemo(() => createMedia(theme.breakpoints), [theme.key]);

  return (
    <WrappedMediaContext.Provider value={media}>
      <media.MediaContextProvider>{children}</media.MediaContextProvider>
    </WrappedMediaContext.Provider>
  );
};

export const Media: React.FC<React.ComponentProps<CreateMediaResult['Media']>> = (props) => {
  const { Media: FresnelMedia } = useContext(WrappedMediaContext);
  return <FresnelMedia {...props} />;
};
