import { useContext, createContext } from 'react';

export interface ToastDefinition {
  id: number;
  message: string;
  messageOnly?: boolean;
  type: 'info' | 'success' | 'warning' | 'error';
}

export interface CreateToastOptions {
  message: string;
  messageOnly?: boolean;
  type?: ToastDefinition['type'];
  lifespan?: number;
}

export interface ToastContext {
  createToast: (options: CreateToastOptions) => number;
  removeToast: (id: number) => void;
}

export const ToastContext = /* @__PURE__ */ createContext<ToastContext>({
  createToast: () => NaN,
  removeToast: () => {},
});

export function useToast() {
  return useContext(ToastContext);
}
