import { flatten } from './flatten';

type ClassNames = (string | false | undefined | null | ClassNames)[];

/**
 * Constructs a CSS class string from an array (or nested array) of class names.
 */
export function classNames(classes: ClassNames) {
  return flatten(classes).filter(Boolean).join(' ');
}
