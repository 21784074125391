import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const NotificationOn: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0201 20.75C11.2965 20.75 10.7337 20.2031 10.7337 19.5H9.44722C9.44722 20.9062 10.5729 22 12.0201 22C13.4271 22 14.593 20.9062 14.593 19.5H13.3065C13.3065 20.2031 12.7035 20.75 12.0201 20.75ZM14.9627 3.97201C14.2592 3.63155 13.4821 3.40768 12.6633 3.32813V2.625C12.6633 2.3125 12.3417 2 12.0201 2C11.6583 2 11.3769 2.3125 11.3769 2.625V3.32813C8.12058 3.64063 5.5477 6.17969 5.5477 9.26563C5.5477 12.9766 4.70348 14.0703 3.57785 15.0859C3.01503 15.6328 2.85422 16.4141 3.13563 17.1172C3.41704 17.8203 4.14066 18.25 4.94469 18.25H19.0955C19.8593 18.25 20.583 17.8203 20.8644 17.1172C21.1458 16.4141 20.985 15.6328 20.4222 15.0859C19.6212 14.3632 18.9627 13.601 18.6495 11.8843C18.3204 11.96 17.9774 12 17.625 12C17.5387 12 17.4531 11.9976 17.368 11.9929C17.7213 14.0219 18.512 15.0446 19.5377 15.9844C19.9397 16.375 19.6583 17 19.0955 17H4.94469C4.34167 17 4.06026 16.375 4.46227 15.9844C5.86931 14.6953 6.83414 13.25 6.83414 9.26563C6.83414 6.64844 9.16581 4.5 12.0201 4.5C12.7303 4.5 13.4098 4.63684 14.0288 4.88351C14.2934 4.53665 14.6083 4.2293 14.9627 3.97201Z"
          {...fillA}
        />
        <path
          d="M21 7.55556C21 9.39651 19.489 10.8889 17.625 10.8889C15.761 10.8889 14.25 9.39651 14.25 7.55556C14.25 5.71461 15.761 4.22223 17.625 4.22223C19.489 4.22223 21 5.71461 21 7.55556Z"
          {...fillB}
        />
      </>
    );
  },

  viewbox: [0, 0, 24, 24],
};
