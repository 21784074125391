import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const EyeHidden: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M22.7941 19.5558L2.26989 3.10568C2.16693 3.03523 2.02964 3 1.92668 3C1.72075 3 1.58346 3.07045 1.4805 3.17613L1.13729 3.63405C1.06864 3.73973 1 3.8454 1 3.9863C1 4.16243 1.10296 4.33855 1.24025 4.409L21.7644 20.8943C21.8331 20.9648 21.9704 21 22.0733 21C22.2793 21 22.4165 20.9296 22.5195 20.7887L22.8627 20.3659C22.9314 20.2603 23 20.1546 23 20.0137C23 19.8376 22.897 19.6614 22.7941 19.5558ZM11.1934 8.14286L15.8268 11.8415C15.7582 9.72798 14.0764 8.03718 12.0172 8.03718C11.7426 8.07241 11.468 8.07241 11.1934 8.14286ZM12.8066 15.8571L8.17317 12.1585C8.24181 14.272 9.92356 15.9276 12.0172 15.9276C12.2574 15.9276 12.532 15.9276 12.8066 15.8571ZM12.0172 6.90998C15.3807 6.90998 18.5039 8.84736 20.1513 11.9824C19.7395 12.7926 19.259 13.4971 18.6412 14.1311L19.9454 15.1526C20.6661 14.3777 21.2839 13.4971 21.7644 12.5108C21.8331 12.3699 21.9017 12.1937 21.9017 12.0176C21.9017 11.8063 21.8331 11.6301 21.7644 11.4892C19.9111 7.75538 16.2044 5.21918 12.0172 5.21918C10.7473 5.21918 9.54602 5.46575 8.41342 5.88845L9.9922 7.19178C10.6443 7.01566 11.3307 6.90998 12.0172 6.90998ZM12.0172 17.0548C8.61934 17.0548 5.4961 15.1174 3.84867 11.9824C4.26053 11.2074 4.74103 10.5029 5.35881 9.86888L4.0546 8.84736C3.33385 9.62231 2.71607 10.5029 2.23557 11.4892C2.16693 11.6301 2.13261 11.8063 2.13261 11.9824C2.13261 12.1937 2.16693 12.3699 2.23557 12.5108C4.08892 16.2446 7.79563 18.7456 12.0172 18.7456C13.2527 18.7456 14.454 18.499 15.5866 18.1115L14.0078 16.8082C13.3557 16.9843 12.6693 17.0548 12.0172 17.0548Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
