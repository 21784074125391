import { useContext, createContext } from 'react';
import { Theme, DEFAULT_THEME, createTheme } from '../../libs/theme';

export interface ThemeContext {
  theme: Theme | null;
}

export const ThemeContext = /* @__PURE__ */ createContext<ThemeContext>({
  theme: null,
});

interface UseThemeOptions {
  /**
   * There are some design cases where `{color}_lighter` / `{color}_darker`
   * semantics should be reversed. When this option is true, `{color}_lighter`
   * becomes `{color}_darker` and vice versa.
   *
   * Use with care.
   */
  inverseSemantics?: boolean;
}

export function useTheme(options?: UseThemeOptions) {
  const theme = useContext(ThemeContext).theme ?? DEFAULT_THEME;

  if (options?.inverseSemantics) {
    const themeWithInverseSemantics = createTheme({ ...theme.config, inverseSemantics: true });
    return themeWithInverseSemantics;
  }

  return theme;
}
