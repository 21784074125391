import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Home: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M21.9217 10.9329L19.5342 8.91946V5.77852C19.5342 5.65772 19.4168 5.53691 19.2994 5.53691H18.5166C18.3601 5.53691 18.2818 5.65772 18.2818 5.77852V7.87248L12.8023 3.28188C12.5675 3.08054 12.2935 3 12.0196 3C11.7065 3 11.4325 3.08054 11.1977 3.28188L2.11742 10.9329C2.03914 10.9732 2 11.0537 2 11.1745C2 11.255 2 11.3356 2.03914 11.3758L2.43053 11.8591C2.50881 11.9396 2.58708 11.9799 2.7045 11.9799C2.78278 11.9799 2.82192 11.9799 2.9002 11.8993L4.50489 10.5705V20.3557C4.50489 20.7181 4.77887 21 5.13112 21H10.1409C10.454 21 10.7671 20.7181 10.7671 20.3557V15.2013H13.272V20.3557C13.272 20.7181 13.546 21 13.8982 21H18.908C19.2211 21 19.5342 20.7181 19.5342 20.3557V10.5705L21.0998 11.8993C21.1781 11.9799 21.2172 11.9799 21.2955 11.9799C21.4129 11.9799 21.4912 11.9396 21.5695 11.8591L21.9609 11.3758C22 11.3356 22 11.255 22 11.1745C22 11.0537 21.9609 10.9732 21.9217 10.9329ZM18.2427 19.7114L14.4853 19.7517V14.557C14.4853 14.2349 14.2114 13.9128 13.8982 13.9128H10.1409H10.1018C9.78865 13.9128 9.47554 14.1946 9.47554 14.557V19.7114H5.75734V9.52349L12.0196 4.24832L18.2818 9.52349L18.2427 19.7114Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
