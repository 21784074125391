/**
 * Flattens an array recursively.
 */
export function flatten<T extends any[]>(array: T, destination: any[] = []) {
  for (const entry of array) {
    if (Array.isArray(entry)) {
      flatten(entry, destination);
    } else {
      destination.push(entry);
    }
  }

  return destination;
}
