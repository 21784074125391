import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const ArrowLeft: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12.1916 19.8644L12.9047 19.1774C13.083 18.9966 13.083 18.7073 12.9047 18.5627L7.37827 12.922H19.5721C19.7861 12.922 20 12.7412 20 12.4881V11.4757C20 11.2588 19.7861 11.0418 19.5721 11.0418H7.37827L12.9047 5.43729C13.083 5.29266 13.083 5.00339 12.9047 4.8226L12.1916 4.13559C12.049 3.9548 11.7638 3.9548 11.5855 4.13559L4.1337 11.6927C3.95543 11.8734 3.95543 12.1266 4.1337 12.3073L11.5855 19.8644C11.7638 20.0452 12.049 20.0452 12.1916 19.8644Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
