import React from 'react';
import { forwardRefWrapper } from '../../libs/forward-ref-wrapper';

interface EmojiProps extends React.HTMLAttributes<HTMLSpanElement> {
  symbol: string;
  label?: string;
}

/**
 * An a11y-friendly accessible emoji component.
 * Based on `a11y-react-emoji` with added `forwardRef` compatibility.
 *
 * @see https://github.com/seanmcp/a11y-react-emoji
 */
export const Emoji = /* @__PURE__ */ forwardRefWrapper<HTMLSpanElement, EmojiProps>('Emoji', (props, externalRef) => {
  const { symbol, label, ...otherProps } = props;
  return (
    <span role="img" aria-label={label} aria-hidden={label ? undefined : true} {...otherProps} ref={externalRef}>
      {symbol}
    </span>
  );
});
