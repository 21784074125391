import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const QuestionOutlined: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12 15.3871C11.3548 15.3871 10.871 15.9113 10.871 16.5161C10.871 17.1613 11.3548 17.6452 12 17.6452C12.6048 17.6452 13.129 17.1613 13.129 16.5161C13.129 15.9113 12.6048 15.3871 12 15.3871ZM12.2823 14.4194C12.5645 14.4194 12.7661 14.2177 12.7661 13.9355C12.7661 12.2016 15.9113 12.3226 15.9113 9.62097C15.9113 7.56452 14.0565 6.35484 11.9597 6.35484C10.1452 6.35484 9.1371 7.08065 8.29032 8.16935C8.12903 8.41129 8.16935 8.69355 8.41129 8.85484L8.93548 9.25806C9.1371 9.37903 9.41936 9.33871 9.58065 9.1371C10.1855 8.37097 10.7903 7.96774 11.9597 7.96774C13.5726 7.96774 14.2984 8.81452 14.2984 9.62097C14.2984 11.3548 11.1532 11.1129 11.1532 13.9355C11.1532 14.2177 11.3952 14.4194 11.6371 14.4194H12.2823ZM12 3.29032C16.7581 3.29032 20.7097 7.20161 20.7097 12C20.7097 16.8387 16.7984 20.7097 12 20.7097C7.16129 20.7097 3.29032 16.8387 3.29032 12C3.29032 7.20161 7.16129 3.29032 12 3.29032ZM12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12C22 6.51613 17.4839 2 12 2Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
