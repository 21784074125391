import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Logout: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M3.87592 4C2.82071 4 2 4.91667 2 6V18C2 19.125 2.82071 20 3.87592 20H9.03469C9.26917 20 9.50366 19.7917 9.50366 19.5V19.1667C9.50366 18.9167 9.26917 18.6667 9.03469 18.6667H3.87592C3.52418 18.6667 3.25061 18.375 3.25061 18V6C3.25061 5.66667 3.52418 5.33333 3.87592 5.33333H9.03469C9.26917 5.33333 9.50366 5.125 9.50366 4.83333V4.5C9.50366 4.25 9.26917 4 9.03469 4H3.87592ZM14.7797 4.83333L14.467 5.125C14.3107 5.33333 14.3107 5.625 14.467 5.83333L19.6258 11.2917H8.72203C8.44846 11.2917 8.25305 11.5417 8.25305 11.7917V12.2083C8.25305 12.5 8.44846 12.7083 8.72203 12.7083H19.6258L14.5061 18.2083C14.3107 18.4167 14.3107 18.7083 14.5061 18.9167L14.7797 19.2083C14.936 19.4167 15.2487 19.4167 15.4441 19.2083L21.8534 12.375C22.0489 12.1667 22.0489 11.875 21.8534 11.6667L15.4441 4.83333C15.2487 4.625 14.936 4.625 14.7797 4.83333Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
