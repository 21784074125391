import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const PaintPalette: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M6.38346 12.3125C5.83688 12.3125 5.44647 12.7422 5.44647 13.25C5.44647 13.7969 5.83688 14.1875 6.38346 14.1875C6.891 14.1875 7.32045 13.7969 7.32045 13.25C7.32045 12.7422 6.891 12.3125 6.38346 12.3125ZM7.63278 7.9375C7.0862 7.9375 6.69579 8.36719 6.69579 8.875C6.69579 9.42188 7.0862 9.8125 7.63278 9.8125C8.14032 9.8125 8.56977 9.42188 8.56977 8.875C8.56977 8.36719 8.14032 7.9375 7.63278 7.9375ZM12.0054 2C11.3417 2 10.639 2.07812 9.97526 2.19531C6.07113 2.97656 2.94782 6.10156 2.20603 9.96875C0.839586 16.8047 6.53962 22 11.3417 22C11.654 22 12.0054 22 12.3177 21.9609C13.9184 21.6875 14.6993 19.8125 13.9575 18.3672C13.0595 16.5703 14.3479 14.5 16.339 14.5H19.4623C20.8678 14.5 22 13.3672 22 11.9609C21.961 6.45312 17.5102 2 12.0054 2ZM19.4623 13.25H16.339C14.9726 13.25 13.7232 13.9531 12.9814 15.125C12.2787 16.2969 12.2397 17.7031 12.8643 18.9141C13.0205 19.3047 13.0205 19.7734 12.8253 20.1641C12.7472 20.3203 12.552 20.6328 12.1225 20.7109C11.8492 20.75 11.615 20.7891 11.3417 20.7891C9.19444 20.7891 6.891 19.5391 5.2903 17.5859C3.57248 15.4766 2.90878 12.8594 3.41631 10.2031C4.08002 6.84375 6.81291 4.10938 10.2095 3.44531C10.7951 3.32813 11.4198 3.28906 12.0054 3.28906C16.8075 3.28906 20.7116 7.15625 20.7507 11.9609C20.7507 12.6641 20.1651 13.25 19.4623 13.25ZM16.378 7.3125C15.8315 7.3125 15.4411 7.74219 15.4411 8.25C15.4411 8.79688 15.8315 9.1875 16.378 9.1875C16.8856 9.1875 17.315 8.79688 17.315 8.25C17.315 7.74219 16.8856 7.3125 16.378 7.3125ZM11.3808 5.4375C10.8342 5.4375 10.4438 5.86719 10.4438 6.375C10.4438 6.92188 10.8342 7.3125 11.3808 7.3125C11.8883 7.3125 12.3177 6.92188 12.3177 6.375C12.3177 5.86719 11.8883 5.4375 11.3808 5.4375Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
