import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Exclaim: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12C22 6.51613 17.4839 2 12 2ZM11.5161 6.83871C11.2339 6.83871 11.0323 7.08064 11.0323 7.3629L11.3145 14.1371C11.3145 14.379 11.5565 14.5806 11.7984 14.5806H12.1613C12.4032 14.5806 12.6452 14.379 12.6452 14.1371L12.9274 7.3629C12.9274 7.08064 12.7258 6.83871 12.4436 6.83871H11.5161ZM12 15.3871C11.3549 15.3871 10.871 15.9113 10.871 16.5161C10.871 17.1613 11.3549 17.6452 12 17.6452C12.6049 17.6452 13.129 17.1613 13.129 16.5161C13.129 15.9113 12.6049 15.3871 12 15.3871Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
