import { createMedia as fresnel } from '@artsy/fresnel';
import { BreakpointConfig } from './theme-types';

/**
 * Wraps `createMedia` from `@artsy/fresnel`
 * with sensible defaults for Magic UI.
 */
export function createMedia(breakpoints: BreakpointConfig) {
  return fresnel({
    interactions: {
      landscape: 'not all and (orientation: landscape)',
      portrait: 'not all and (orientation: portrait)',
      hover: '(hover: hover)',
      notHover: '(hover: none)',
    },

    breakpoints,
  });
}

export type CreateMediaResult = ReturnType<typeof createMedia>;
