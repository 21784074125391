import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Info: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12C22 6.51613 17.4839 2 12 2ZM11.9997 6.19352C11.2739 6.19352 10.7094 6.79836 10.7094 7.48384C10.7094 8.20965 11.2739 8.77417 11.9997 8.77417C12.6852 8.77417 13.29 8.20965 13.29 7.48384C13.29 6.79836 12.6852 6.19352 11.9997 6.19352ZM10.5481 15.8709C10.2658 15.8709 10.0642 16.1129 10.0642 16.3548V16.6774C10.0642 16.9597 10.2658 17.1613 10.5481 17.1613H13.4513C13.6933 17.1613 13.9352 16.9597 13.9352 16.6774V16.3548C13.9352 16.1129 13.6933 15.8709 13.4513 15.8709H12.9675V10.2258C12.9675 9.98384 12.7255 9.74191 12.4836 9.74191H10.5481C10.2658 9.74191 10.0642 9.98384 10.0642 10.2258V10.5484C10.0642 10.8306 10.2658 11.0322 10.5481 11.0322H11.032V15.8709H10.5481Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
