/* eslint-disable no-nested-ternary */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import composeRefs from '@seznam/compose-react-refs';
import { useSlide } from '../../../hooks/transitions';
import { FlexibleDiv } from '../../layout/flexible-div';
import { EphemeralPortal } from '../../layout/ephemeral-portal';
import { classNames } from '../../../libs/class-names';
import { useTheme } from '../../theme/use-theme';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';
import { ThemeProvider } from '../../theme';
import { useIsomorphicLayoutEffect } from '../../../hooks/use-isomorphic-layout-effect';

/**
 * There is a known bug in StorybookJS related to incompatible versions of
 * `react-popper`. We workaround this issue by using `yarn` aliases to support
 * multiple versions of the same NPM package. This means projects that consume
 * Magic Storybook _must_ use `yarn` until the issue reference below is
 * resolved.
 *
 * @see https://github.com/storybookjs/storybook/issues/10982
 */
/* eslint-disable-next-line import/order */
import { Manager, Popper, Reference } from 'react-popper-2';

import styles from './tooltip.less';

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  anchor: React.ReactNode;
  in?: boolean;
  delay?: number | { show: number; hide: number };
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  arrow?: boolean;
  overflow?: boolean;
  waitForPointerExit?: boolean;
}

/**
 * A low-level tooltip component. Tooltip visibility state should be managed by
 * the consuming component (or you can use `<AnchoredTooltip />`).
 */
export const Tooltip = /* @__PURE__ */ forwardRefWrapper<HTMLDivElement, TooltipProps>(
  'Tooltip',

  {
    in: false,
    delay: { show: 0, hide: 1000 },
    placement: 'top',
    waitForPointerExit: true,
    arrow: true,
    overflow: false,
  },

  (props, externalRef) => {
    const {
      children,
      anchor,
      in: inProp,
      delay,
      placement,
      waitForPointerExit,
      arrow,
      overflow,
      ...otherProps
    } = props;

    const [shouldShow, setShouldShow] = useState(inProp);
    const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
    const popperUpdate = useRef<(() => void) | null>(null);
    const getSlideProps = useSlide();
    const showDelay = typeof delay === 'number' ? delay : delay?.show || 0;
    const hideDelay = typeof delay === 'number' ? delay : delay?.hide || 1000;

    // --- Effects

    // Show/hide the tooltip while
    // accounting for the configured delay.
    useEffect(() => {
      let showTimeout: number;
      let hideTimeout: number;

      if (inProp && !shouldShow) {
        if (showDelay) showTimeout = window.setTimeout(() => setShouldShow(true), showDelay);
        else setShouldShow(true);
      } else if (!inProp && (!waitForPointerExit ? true : !isMouseOverTooltip) && shouldShow) {
        if (hideDelay) hideTimeout = window.setTimeout(() => setShouldShow(false), hideDelay);
        else setShouldShow(false);
      }

      return () => {
        if (showTimeout) clearTimeout(showTimeout);
        if (hideTimeout) clearTimeout(hideTimeout);
      };
    }, [inProp, shouldShow, isMouseOverTooltip, showDelay, hideDelay]);

    // Force-update the underlying Popper.js
    // instance if `shouldShow` changes.
    useIsomorphicLayoutEffect(() => {
      if (popperUpdate.current) popperUpdate.current();
    }, [shouldShow]);

    // --- Callbacks

    /**
     * Keep showing the tooltip if mouse enters the content area, if there
     * is a `hideDelay` value set, we also start the timeout. The timeout
     * itself does not hide the tooltip, for that we wait until the mouse
     * leaves the content area (see `handleTooltipContentMouseLeave` below).
     */
    const handleTooltipContentMouseEnter = useCallback(() => {
      setShouldShow(true);
      setIsMouseOverTooltip(true);
    }, []);

    /**
     * Stops showing the tooltip when mouse leaves the content area, while
     * respecting the current `hideDelay` prop value.
     */
    const handleTooltipContentMouseLeave = useCallback(() => {
      setIsMouseOverTooltip(false);
    }, []);

    // --- Rendering

    const tooltip = (
      <Popper
        placement={placement}
        modifiers={[
          { name: 'offset', enabled: true, options: { offset: [0, 10] } },
          { name: 'preventOverflow', enabled: !overflow },
          { name: 'arrow', options: { padding: 5 } },
        ]}
      >
        {({ placement: popperPlacement, ref, style, arrowProps, update }) => {
          popperUpdate.current = update; // Save a reference to `scheduleUpdate`
          return (
            <div className={styles.Tooltip} {...otherProps} style={style} ref={composeRefs(ref, externalRef)}>
              <AnimatePresence>
                {shouldShow && (
                  <motion.div
                    {...getSlideProps({
                      x: popperPlacement.startsWith('right') ? 5 : popperPlacement.startsWith('left') ? -5 : 0,
                      y: popperPlacement.startsWith('bottom') ? 5 : popperPlacement.startsWith('top') ? -5 : 0,
                    })}
                    transition={{ duration: 0.2 }}
                    className={classNames([styles.content, arrow && styles.hasArrow])}
                    x-placement={popperPlacement}
                    onMouseEnter={handleTooltipContentMouseEnter}
                    onMouseLeave={handleTooltipContentMouseLeave}
                  >
                    {arrow && <div className={styles.arrow} ref={arrowProps.ref} style={arrowProps.style} />}
                    <FlexibleDiv initialDelay={0.2} onAnimationFrame={update}>
                      {children}
                    </FlexibleDiv>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        }}
      </Popper>
    );

    const anchorWithRef = useCallback(({ ref }) => <div ref={ref}>{anchor}</div>, [anchor]);

    return (
      <div className={styles.Tooltip}>
        <Manager>
          <Reference>{anchorWithRef}</Reference>
          <EphemeralPortal>
            <ThemeProvider theme={useTheme()}>{tooltip}</ThemeProvider>
          </EphemeralPortal>
        </Manager>
      </div>
    );
  },
);
