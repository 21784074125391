import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Copy: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M20.3973 4.57812L18.3482 2.58594C17.9866 2.23438 17.5045 2 16.9821 2H10.0714C8.98661 2 8.14286 2.85938 8.14286 3.875V5.75H4.92857C3.84375 5.75 3 6.60938 3 7.625V20.125C3 21.1797 3.84375 22 4.92857 22H13.9286C14.9732 22 15.8571 21.1797 15.8571 20.125V18.25H19.0714C20.1161 18.25 21 17.4297 21 16.375V5.90625C21 5.39844 20.7589 4.92969 20.3973 4.57812ZM17.1429 3.28906C17.2232 3.32812 17.3438 3.36719 17.4241 3.44531L19.5134 5.47656C19.5938 5.55469 19.6339 5.67188 19.6741 5.75H17.1429V3.28906ZM14.5714 20.125C14.5714 20.4766 14.25 20.75 13.9286 20.75H4.92857C4.56696 20.75 4.28571 20.4766 4.28571 20.125V7.625C4.28571 7.3125 4.56696 7 4.92857 7H8.14286V16.375C8.14286 17.4297 8.98661 18.25 10.0714 18.25H14.5714V20.125ZM19.7143 16.375C19.7143 16.7266 19.3929 17 19.0714 17H10.0714C9.70982 17 9.42857 16.7266 9.42857 16.375V3.875C9.42857 3.5625 9.70982 3.25 10.0714 3.25H15.8571V6.0625C15.8571 6.60938 16.2589 7 16.8214 7H19.7143V16.375Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
