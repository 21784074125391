import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Hourglass: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9929 27.9988L39.4315 19.1057C40.6892 17.9206 39.7236 16 37.87 16H18.13C16.2764 16 15.3108 17.9206 16.5685 19.1057L26.0071 27.9988L16.527 37.0674C15.3344 38.2083 16.297 40 18.1026 40H37.8974C39.703 40 40.6656 38.2083 39.473 37.0674L29.9929 27.9988Z"
          {...fillA}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 34.8764C23.8386 35.0576 20.716 36.0988 18 38H38C35.284 36.0988 32.1614 35.0576 29 34.8764V27L33 23H23L27 27V34.8764Z"
          {...fillB}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.warning.base : theme.hex.warning.darker),
  colorB: (theme) => theme.hex.warning.lightest,
};
