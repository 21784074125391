import { forwardRef, ForwardRefExoticComponent, ForwardRefRenderFunction, PropsWithoutRef, RefAttributes } from 'react';

/**
 * Wraps `React.forwardRef` and applies a semantic `displayName` and
 * `defaultProps` without side effects.
 *
 * This wrapper is required to properly tree-shake `React.forwardRef`
 * components, otherwise the minifier can't understand that `defaultProps` and
 * `displayName` are pure.
 */
export function forwardRefWrapper<T, P = {}>(
  name: string,
  render: ForwardRefRenderFunction<T, P>,
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>>;

export function forwardRefWrapper<T, P = {}>(
  name: string,
  defaultProps: Partial<PropsWithoutRef<P> & RefAttributes<T>> | undefined,
  render: ForwardRefRenderFunction<T, P>,
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>>;

export function forwardRefWrapper<T, P = {}>(
  name: string,
  defaultPropsOrRender: Partial<PropsWithoutRef<P> & RefAttributes<T>> | undefined | ForwardRefRenderFunction<T, P>,
  definitelyRender?: ForwardRefRenderFunction<T, P>,
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>> {
  const render = typeof defaultPropsOrRender === 'object' ? definitelyRender : defaultPropsOrRender;
  const defaultProps = typeof defaultPropsOrRender === 'function' ? {} : defaultPropsOrRender;

  const comp = forwardRef(render!);
  comp.displayName = name;
  comp.defaultProps = defaultProps;
  return comp;
}
