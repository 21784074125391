/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useRef } from 'react';
import { PressEvent } from '@react-types/shared';
import { usePress, useHover } from '@react-aria/interactions';
import composeRefs from '@seznam/compose-react-refs';
import { AnimatePresence, motion } from 'framer-motion';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { Spacer } from '../../layout';
import { Icon, MonochromeIconDefinition } from '../../icons';
import { useScale, useScaleIn } from '../../../hooks/transitions';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';

import styles from './checkbox.less';

const CheckmarkIcon: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12.9374 0.446429L13.2945 0.848214C13.5177 1.02679 13.5177 1.38393 13.2945 1.60714L5.25879 9.59822C5.03558 9.77679 4.67843 9.77679 4.49986 9.59822L0.660575 5.71429C0.43736 5.49107 0.43736 5.17857 0.660575 4.95536L1.01772 4.59821C1.24093 4.375 1.59807 4.375 1.77665 4.59821L4.857 7.67857L12.1784 0.446429C12.357 0.267857 12.7141 0.267857 12.9374 0.446429Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 14, 10],

  color: (theme) => theme.hex.secondary.base,
};

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  color?: 'primary' | 'success' | 'warning' | 'error';
  indeterminate?: boolean;
  onPress?: (event: PressEvent) => void;
}

export const Checkbox = /* @__PURE__ */ forwardRefWrapper<HTMLInputElement, CheckboxProps>(
  'Checkbox',

  { color: 'primary' },

  (props, externalRef) => {
    const { onPress, disabled, checked, label, indeterminate, color, ...otherProps } = props;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { pressProps, isPressed } = usePress({ onPress, isDisabled: disabled });
    const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
    const getScaleInProps = useScaleIn();
    const getScaleProps = useScale();

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return (
      <label className={styles.Checkbox}>
        <input
          type="checkbox"
          disabled={disabled}
          aria-disabled={disabled}
          checked={checked}
          tabIndex={0}
          {...mergeProps(pressProps, focusProps, hoverProps, otherProps)}
          ref={composeRefs(inputRef, externalRef)}
        />

        <span
          className={classNames([
            styles.checkmark,
            styles[`color_${color}`],
            disabled && styles.disabled,
            checked && styles.checked,
            isFocusVisible && styles.focusVisible,
            isPressed && styles.active,
            isHovered && styles.hover,
            indeterminate && styles.indeterminate,
          ])}
          aria-hidden="true"
        >
          <AnimatePresence>
            {isFocusVisible && (
              <motion.span aria-hidden="true" className={styles.focusRing} {...getScaleInProps(1.1)} />
            )}
          </AnimatePresence>

          <AnimatePresence initial={false}>
            {checked && !indeterminate && (
              <motion.span className={styles.checkmarkIcon} {...getScaleProps(0.7)} key={0}>
                <Icon size={14} type={CheckmarkIcon} />
              </motion.span>
            )}

            {checked && !!indeterminate && (
              <motion.span className={styles.indeterminancyIcon} {...getScaleProps(0.7)} key={1}>
                <span />
              </motion.span>
            )}
          </AnimatePresence>
        </span>

        {label && (
          <>
            <Spacer size={8} inline />
            <span>{label}</span>
          </>
        )}
      </label>
    );
  },
);
