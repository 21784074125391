import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Document: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M18.4531 5.82813L15.4271 2.54688C15.099 2.19531 14.625 2 14.1875 2H6.75C5.76562 2 5 2.85938 5 3.875V20.125C5 21.1797 5.76562 22 6.75 22H17.25C18.1979 22 19 21.1797 19 20.125V7.15625C19 6.6875 18.7812 6.17969 18.4531 5.82813ZM17.651 6.72656C17.724 6.80469 17.7604 6.92188 17.7969 7H14.3333V3.28906C14.4062 3.32813 14.5156 3.36719 14.5885 3.44531L17.651 6.72656ZM17.25 20.75H6.75C6.42188 20.75 6.16667 20.4766 6.16667 20.125V3.875C6.16667 3.5625 6.42188 3.25 6.75 3.25H13.1667V7.3125C13.1667 7.85938 13.5312 8.25 14.0417 8.25H17.8333V20.125C17.8333 20.4766 17.5417 20.75 17.25 20.75ZM15.5 12.3299C15.5 12.0955 15.2812 11.8611 15.0625 11.8611H8.9375C8.68229 11.8611 8.5 12.0955 8.5 12.3299V12.6424C8.5 12.9158 8.68229 13.1111 8.9375 13.1111H15.0625C15.2812 13.1111 15.5 12.9158 15.5 12.6424V12.3299ZM15.5 16.2188C15.5 15.9844 15.2812 15.75 15.0625 15.75H8.9375C8.68229 15.75 8.5 15.9844 8.5 16.2188V16.5313C8.5 16.8047 8.68229 17 8.9375 17H15.0625C15.2812 17 15.5 16.8047 15.5 16.5313V16.2188Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
