import { Theme } from './theme-types';
import { createTheme } from './create-theme';

export const DEFAULT_THEME = /* @__PURE__ */ createTheme({
  primaryColor: {
    base: '#6851ff',
    lighter: '#a796ff',
    lightest: '#f1eeff',
    darker: '#4e3bdb',
    darkest: '#3728b7',
  },

  lightColor: '#ffffff',
  darkColor: '#000000',

  midColor: {
    base: '#757575',
    lighter: '#bdbdbd',
    lightest: '#eeeeee',
    darker: '#545454',
    darkest: '#212121',
  },

  successColor: {
    base: '#00c988',
    lighter: '#5feea4',
    lightest: '#ddfde6',
    darker: '#00ac85',
    darkest: '#00907e',
  },

  warningColor: {
    base: '#bf9046',
    lighter: '#ebd092',
    lightest: '#fbf5df',
    darker: '#a47333',
    darkest: '#895923',
  },

  errorColor: {
    base: '#ff5635',
    lighter: '#FBA67F',
    lightest: '#FAE6DC',
    darker: '#d02f20',
    darkest: '#B71C1A',
  },
}) as Theme;
