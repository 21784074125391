import React from 'react';
import { useTheme } from './use-theme';
import { mergeProps } from '../../libs/merge-props';
import { getThemeVariables } from '../../libs/theme';

interface InverseThemeSemanticsProps {
  active?: boolean;
}

/**
 * There are some design cases where `{color}_lighter` / `{color}_darker` semantics
 * should be reversed. Wrapping elements with this component will inverse the
 * expected theme semantics to support these edge cases.
 *
 * In other words, `{color}_lighter` will become `{color}_darker` and vice versa.
 *
 * Use with care.
 */
export const InverseThemeSemantics: React.FC<InverseThemeSemanticsProps> = (props) => {
  const { active, children } = props;

  const theme = useTheme({ inverseSemantics: true });
  const { variables: style } = getThemeVariables(theme);

  const wrappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child,

        mergeProps({
          ...child.props,
          style,
        }),
      );
    }

    return child;
  });

  return active ? <>{wrappedChildren}</> : <>{children}</>;
};

InverseThemeSemantics.defaultProps = {
  active: true,
};
