import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Success: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M37.4267 18.2021L23.6149 32.378L18.5733 27.1496C18.3107 26.934 17.8906 26.934 17.6805 27.1496L16.1575 28.7128C15.9475 28.9284 15.9475 29.3596 16.1575 29.6291L23.1947 36.7979C23.4573 37.0674 23.8249 37.0674 24.0875 36.7979L39.8425 20.6277C40.0525 20.4121 40.0525 19.9809 39.8425 19.7113L38.3195 18.2021C38.1094 17.9326 37.6893 17.9326 37.4267 18.2021Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => theme.hex.primary.darker,
  colorB: (theme) => theme.hex.primary.lightest,
};
