import React, { useContext, useMemo } from 'react';
import { Theme } from '../../libs/theme/theme-types';
import { getThemeVariables } from '../../libs/theme/theme-variables';
import { DEFAULT_THEME } from '../../libs/theme/default-theme';
import { classNames } from '../../libs/class-names';
import { ThemeContext } from './use-theme';
import { MediaProvider } from './media-provider';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';

interface ThemeProviderProps {
  theme?: Theme;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  const { theme = DEFAULT_THEME, children } = props;

  const isRoot = !!useContext(ThemeContext).theme;
  const { variables, applyToDocument } = getThemeVariables(theme);

  useIsomorphicLayoutEffect(() => {
    if (isRoot) applyToDocument();
  }, [theme.key, isRoot]);

  const themeVariablesMemoized = useMemo(() => variables, [theme.key]);
  const themeMetadata = classNames([theme.isDarkTheme ? 'dark' : 'light', theme.isLowContrast && 'lowContrast']);
  const themeCtx: ThemeContext = useMemo(() => ({ theme }), [theme.key]);

  /*

    NOTES
    ~~~~~

    We look for the following potential values in the `data-mg-theme` attribute:

      - `light` | `dark`
      - `lowContrast`

    Our LESS mixins (`#mg.darkTheme`, `#mg.lightTheme`, and `#mg.lowContrast`)
    leverage these values to apply theme-specific styles. Because the attribute
    can be checked in any order, any of the following formats are considered
    valid:

      - `[data-mg-theme~='light'] [data-mg-theme~='lowContrast'] ...`
      - `[data-mg-theme~='lowContrast'] [data-mg-theme~='light'] ...`

    To get this flexibility, we have to nest the `data-mg-theme` attribute
    at least twice!

   */

  const attributes = {
    'data-mg-theme': themeMetadata,
  };

  return (
    <ThemeContext.Provider value={themeCtx}>
      <MediaProvider>
        <div {...attributes}>
          <div {...attributes} style={themeVariablesMemoized as any}>
            {children}
          </div>
        </div>
      </MediaProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.defaultProps = {};

ThemeProvider.displayName = 'ThemeProvider';
