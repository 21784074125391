import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const CaretDown: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M11.4698 15.7909C11.7817 16.06 12.2183 16.06 12.5302 15.8447L19.7661 9.49359C20.078 9.22447 20.078 8.79389 19.7661 8.5786L19.3294 8.20184C19.0175 7.93272 18.5809 7.93272 18.269 8.20184L12.0312 13.6918L5.73099 8.20184C5.4191 7.93272 4.98246 7.93272 4.67056 8.20184L4.23392 8.5786C3.92203 8.79389 3.92203 9.22447 4.23392 9.49359L11.4698 15.7909Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
