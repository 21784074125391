import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const CaretLeft: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M8.20913 11.4698C7.94001 11.7817 7.94001 12.2183 8.1553 12.5302L14.5064 19.7661C14.7755 20.078 15.2061 20.078 15.4214 19.7661L15.7982 19.3294C16.0673 19.0175 16.0673 18.5809 15.7982 18.269L10.3082 12.0312L15.7982 5.73099C16.0673 5.4191 16.0673 4.98246 15.7982 4.67057L15.4214 4.23392C15.2061 3.92203 14.7755 3.92203 14.5064 4.23392L8.20913 11.4698Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
