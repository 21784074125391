import composeRefs from '@seznam/compose-react-refs';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHover } from '@react-aria/interactions';
import { useTheme } from '../../../theme';
import { classNames } from '../../../../libs/class-names';
import { forwardRefWrapper } from '../../../../libs/forward-ref-wrapper';
import { Icon, IconColorProvider, MonochromeIconDefinition } from '../../../icons';
import { Spacer } from '../../spacer';

import styles from './tab.less';
import { mergeProps } from '../../../../libs/merge-props';

interface TabProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  panelID: string;
  allTabIDs: string[];
  index: number;
  activateTab: (tabID: string) => void;
  selected: boolean;
  orientation: 'horizontal' | 'vertical';
  size: 'sm' | 'md' | 'lg';
  useAnimation: boolean;
  icon?: MonochromeIconDefinition;
}

function getPrevTabID(tabIDs: string[], index: number) {
  const maxIndex = tabIDs.length - 1;
  const nextIndex = index <= 0 ? maxIndex : index - 1;
  return tabIDs[nextIndex];
}

function getNextTabID(tabIDs: string[], index: number) {
  const maxIndex = tabIDs.length - 1;
  const nextIndex = index >= maxIndex ? 0 : index + 1;
  return tabIDs[nextIndex];
}

/**
 * Determines a _default_ color for icons nested in a `<Tab>`.
 */
function useIconColor(
  orientation: TabProps['orientation'],
  isHovered?: boolean,
  isSelected?: boolean,
): string | undefined {
  const theme = useTheme();

  if (orientation === 'horizontal') {
    if (isHovered && !isSelected) return theme.hex.primary.darker;
    if (isSelected) return theme.hex.secondary.base;
    return theme.hex.tertiary.base;
  }

  return theme.hex.tertiary.base;
}

export const Tab = /* @__PURE__ */ forwardRefWrapper<HTMLButtonElement, TabProps>('Tab', (props, externalRef) => {
  const {
    id,
    panelID,
    allTabIDs,
    index,
    activateTab,
    selected,
    orientation,
    size,
    useAnimation,
    icon,
    children,
  } = props;

  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    // If the current tab is selected, give it focus
    if (selected && ref.current) ref.current.focus();
  }, [selected]);

  const handleClick = useCallback<React.EventHandler<React.MouseEvent<HTMLButtonElement>>>(
    (e) => {
      e.preventDefault();
      activateTab(id);
    },

    [id],
  );

  const handleKeyDown = useCallback<React.EventHandler<React.KeyboardEvent<HTMLButtonElement>>>(
    (e) => {
      const prevTabID = getPrevTabID(allTabIDs, index);
      const nextTabID = getNextTabID(allTabIDs, index);

      const handleTabChange = (tabID: string) => {
        e.preventDefault();
        activateTab(tabID);
      };

      const handleArrowKey = (assertOrientation: typeof orientation, tabID: string) => {
        if (orientation === assertOrientation) {
          handleTabChange(tabID);
        }
      };

      switch (e.key) {
        case 'ArrowRight':
          handleArrowKey('horizontal', nextTabID);
          break;

        case 'ArrowLeft':
          handleArrowKey('horizontal', prevTabID);
          break;

        case 'ArrowUp':
          handleArrowKey('vertical', prevTabID);
          break;

        case 'ArrowDown':
          handleArrowKey('vertical', nextTabID);
          break;

        case 'Home':
          handleTabChange(allTabIDs[0]);
          break;

        case 'End':
          handleTabChange(allTabIDs[allTabIDs.length - 1]);
          break;

        case 'Delete': // We currently don't handle the 'Delete' case...
        default:
          break;
      }
    },

    [allTabIDs, index, orientation],
  );

  const { isHovered, hoverProps } = useHover({ isDisabled: false });
  const iconColor = useIconColor(orientation, isHovered, selected);

  return (
    <IconColorProvider colorA={iconColor}>
      <button
        className={classNames([
          styles.Tab,
          styles[`size_${size}`],
          selected && styles.selected,
          styles[orientation],
          useAnimation && styles.withAnimation,
        ])}
        id={id}
        type="button"
        role="tab"
        aria-selected={selected}
        aria-controls={panelID}
        tabIndex={selected ? 0 : -1}
        {...mergeProps(hoverProps, {
          onClick: handleClick,
          onKeyDown: handleKeyDown,
        })}
        ref={composeRefs(ref, externalRef)}
      >
        {icon && (
          <>
            <Icon className={styles.icon} size={size === 'sm' ? 20 : 24} type={icon} />
            <Spacer size={8} />
          </>
        )}

        {children}
      </button>
    </IconColorProvider>
  );
});
