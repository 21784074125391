import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Exclaim: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.0002 11.3333C18.7932 11.3333 11.3335 18.8602 11.3335 28C11.3335 37.207 18.7932 44.6667 28.0002 44.6667C37.1399 44.6667 44.6668 37.207 44.6668 28C44.6668 18.8602 37.1399 11.3333 28.0002 11.3333ZM27.1938 19.3978C26.7234 19.3978 26.3874 19.8011 26.3874 20.2715L26.8578 31.5618C26.8578 31.965 27.261 32.3011 27.6642 32.3011H28.2691C28.6723 32.3011 29.0755 31.965 29.0755 31.5618L29.546 20.2715C29.546 19.8011 29.2099 19.3978 28.7395 19.3978H27.1938ZM28.0003 33.6452C26.925 33.6452 26.1185 34.5188 26.1185 35.5269C26.1185 36.6021 26.925 37.4086 28.0003 37.4086C29.0083 37.4086 29.882 36.6021 29.882 35.5269C29.882 34.5188 29.0083 33.6452 28.0003 33.6452Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => (theme.isLightTheme ? theme.hex.error.base : theme.hex.error.darker),
  colorB: (theme) => theme.hex.error.lightest,
};
