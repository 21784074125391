import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const LaptopWithCode: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M10.3125 12.2L10.6562 11.8333C10.75 11.7333 10.75 11.5667 10.6562 11.4667L9.1875 9.86667L10.6562 8.3C10.75 8.2 10.75 8.03333 10.6562 7.93333L10.3125 7.56667C10.2188 7.46667 10.0625 7.46667 9.96875 7.56667L7.96875 9.7C7.84375 9.8 7.84375 9.96667 7.96875 10.0667L9.96875 12.2C10.0625 12.3 10.2188 12.3 10.3125 12.2ZM13.3125 11.8333L13.6563 12.2C13.75 12.3 13.9063 12.3 14 12.2L16 10.0667C16.125 9.96667 16.125 9.8 16 9.7L14 7.56667C13.9063 7.46667 13.75 7.46667 13.6563 7.56667L13.3125 7.93333C13.2188 8.03333 13.2188 8.2 13.3125 8.3L14.7813 9.86667L13.3125 11.4667C13.25 11.5 13.25 11.5667 13.25 11.6333C13.25 11.7 13.25 11.7667 13.3125 11.8333ZM21.5 15.2H20V6.13333C20 4.96667 19.0938 4 18 4H6C4.875 4 4 4.96667 4 6.13333V15.2H2.5C2.21875 15.2 2 15.4667 2 15.7333V17.3333C2 18.8333 3.09375 20 4.5 20H19.5C20.875 20 22 18.8333 22 17.3333V15.7333C22 15.4667 21.75 15.2 21.5 15.2ZM5 6.13333C5 5.56667 5.4375 5.06667 6 5.06667H18C18.5312 5.06667 19 5.56667 19 6.13333V15.2H14.2188C14.0938 15.2 14 15.3333 13.9688 15.4667C13.8438 15.9333 13.4688 16.2667 13 16.2667H11C10.5 16.2667 10.125 15.9333 10 15.4667C9.96875 15.3333 9.875 15.2 9.75 15.2H5V6.13333ZM21 17.3333C21 18.2333 20.3125 18.9333 19.5 18.9333H4.5C3.65625 18.9333 3 18.2333 3 17.3333V16.2667H9.0625C9.28125 16.9 9.84375 17.3333 10.5 17.3333H13.5C14.125 17.3333 14.6875 16.9 14.9063 16.2667H21V17.3333Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
