import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const CaretRight: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M15.7909 12.5302C16.06 12.2183 16.06 11.7817 15.8447 11.4698L9.49359 4.23392C9.22447 3.92203 8.79389 3.92203 8.5786 4.23392L8.20184 4.67057C7.93272 4.98246 7.93272 5.4191 8.20184 5.73099L13.6918 11.9688L8.20184 18.269C7.93272 18.5809 7.93272 19.0175 8.20184 19.3294L8.5786 19.7661C8.79389 20.078 9.22447 20.078 9.49359 19.7661L15.7909 12.5302Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
