import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const SocialShare: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M16.5714 14.25C15.5357 14.25 14.6071 14.707 14 15.4102L10.5714 13.3008C10.9286 12.4922 10.9286 11.543 10.5714 10.7344L14 8.625C14.6071 9.32812 15.5357 9.75 16.5714 9.75C18.4643 9.75 20 8.23828 20 6.375C20 4.51172 18.4643 3 16.5714 3C14.6786 3 13.1429 4.51172 13.1429 6.375C13.1429 6.83203 13.2143 7.28906 13.3929 7.67578L9.96429 9.78516C9.35714 9.08203 8.42857 8.625 7.42857 8.625C5.53571 8.625 4 10.1367 4 12C4 13.8633 5.53571 15.375 7.42857 15.375C8.42857 15.375 9.35714 14.9531 9.96429 14.25L13.3929 16.3594C13.2143 16.7461 13.1429 17.168 13.1429 17.625C13.1429 19.4883 14.6786 21 16.5714 21C18.4643 21 20 19.4883 20 17.625C20 15.7617 18.4643 14.25 16.5714 14.25ZM16.5714 4.125C17.8214 4.125 18.8571 5.14453 18.8571 6.375C18.8571 7.64062 17.8214 8.625 16.5714 8.625C15.2857 8.625 14.2857 7.64062 14.2857 6.375C14.2857 5.14453 15.2857 4.125 16.5714 4.125ZM7.42857 14.25C6.14286 14.25 5.14286 13.2656 5.14286 12C5.14286 10.7695 6.14286 9.75 7.42857 9.75C8.67857 9.75 9.71429 10.7695 9.71429 12C9.71429 13.2656 8.67857 14.25 7.42857 14.25ZM16.5714 19.875C15.2857 19.875 14.2857 18.8906 14.2857 17.625C14.2857 16.3945 15.2857 15.375 16.5714 15.375C17.8214 15.375 18.8571 16.3945 18.8571 17.625C18.8571 18.8906 17.8214 19.875 16.5714 19.875Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
