import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { SSRProvider as ReactAriaSSRProvider } from '@react-aria/ssr';

const canUseDOM = /* @__PURE__ */ Boolean(
  typeof window !== 'undefined' && window.document && window.document.createElement,
);

interface SSRContext {
  isServer: boolean;
  isClient: boolean;
}

const defaultSSRContext: SSRContext = { isServer: !canUseDOM, isClient: canUseDOM };

const SSRContext = /* @__PURE__ */ createContext<SSRContext>(defaultSSRContext);

export const SSRProvider: React.FC = (props) => {
  const { children } = props;

  // Copy the default context so that strict equality
  // checks against the context value are falsey.
  const ctx = { ...defaultSSRContext };

  return (
    <SSRContext.Provider value={ctx}>
      <ReactAriaSSRProvider>{children}</ReactAriaSSRProvider>
    </SSRContext.Provider>
  );
};

export function useSSR() {
  const ctx = useContext(SSRContext);
  const isInSSRContext = ctx !== defaultSSRContext;
  const [isHydrating, setIsHydrating] = useState(canUseDOM && isInSSRContext);

  if (canUseDOM) {
    useLayoutEffect(() => setIsHydrating(false), []);
  }

  return { ...ctx, isHydrating };
}
