import React from 'react';
import { PressEvent } from '@react-types/shared';
import { useHover, usePress } from '@react-aria/interactions';
import { useFocusRing } from '@react-aria/focus';
import { AnimatePresence, motion } from 'framer-motion';
import { mergeProps } from '../../../libs/merge-props';
import { classNames } from '../../../libs/class-names';
import { Icon, MonochromeIconDefinition, IconColorProvider } from '../../icons';
import { Spacer } from '../../layout';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';
import { useScaleIn } from '../../../hooks/transitions';
import { InverseThemeSemantics, useTheme } from '../../theme';
import { BaseColorName, ColorTone } from '../../../libs/theme';

import styles from './text-button.less';

interface TextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  onPress?: (event: PressEvent) => void;
  size?: 'sm' | 'md';
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error';
  leadingIcon?: MonochromeIconDefinition;
  trailingIcon?: MonochromeIconDefinition;
}

/**
 * Determines a _default_ color for icons nested in a `<TextButton>`.
 */
function useIconColor(baseColor?: BaseColorName, inverseSemantics?: boolean, isHovered?: boolean, isPressed?: boolean) {
  const theme = useTheme({ inverseSemantics });

  let tone: ColorTone = 'base';

  // ":hover" state
  if (isHovered) {
    tone = theme.isLightTheme ? 'lighter' : 'darker';
  }

  // ":active" state
  if (isPressed) {
    tone = theme.isLightTheme ? 'darkest' : 'lightest';
  }

  return baseColor ? theme.hex[baseColor][tone] : undefined;
}

export const TextButton = /* @__PURE__ */ forwardRefWrapper<HTMLButtonElement, TextButtonProps>(
  'TextButton',

  { size: 'md', color: 'primary' },

  (props, externalRef) => {
    const { onPress, className, disabled, size, color, leadingIcon, trailingIcon, children, ...otherProps } = props;

    const { isFocusVisible, focusProps } = useFocusRing();
    const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
    const { isPressed, pressProps } = usePress({ onPress, isDisabled: disabled });
    const getScaleProps = useScaleIn();

    const inverseSemantics = color === 'secondary';
    const iconColor = useIconColor(color, inverseSemantics, isHovered, isPressed);

    return (
      <IconColorProvider colorA={iconColor}>
        <InverseThemeSemantics active={inverseSemantics}>
          <button
            className={classNames([
              className,
              styles.TextButton,
              styles[size!],
              styles[`size_${size}`],
              styles[`color_${color}`],
              disabled && styles.disabled,
              isPressed && styles.active,
            ])}
            type="button"
            disabled={disabled}
            aria-disabled={disabled}
            {...mergeProps(pressProps, focusProps, hoverProps, otherProps)}
            ref={externalRef}
          >
            <AnimatePresence>
              {isFocusVisible && (
                <motion.span aria-hidden="true" className={styles.focusRing} {...getScaleProps(1.05)} />
              )}
            </AnimatePresence>

            {leadingIcon && (
              <>
                <Icon size={size === 'sm' ? 20 : 24} type={leadingIcon} />
                <Spacer size={8} />
              </>
            )}

            <span className={styles.buttonLabel}>{children}</span>

            {trailingIcon && (
              <>
                <Spacer size={8} />
                <Icon size={size === 'sm' ? 20 : 24} type={trailingIcon} />
              </>
            )}
          </button>
        </InverseThemeSemantics>
      </IconColorProvider>
    );
  },
);
