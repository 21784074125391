/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useFocus } from '@react-aria/interactions';
import { AnimatePresence, motion } from 'framer-motion';
import composeRefs from '@seznam/compose-react-refs';
import { classNames } from '../../../libs/class-names';
import { mergeProps } from '../../../libs/merge-props';
import { FlexColumn, Outset, FlexRow } from '../../layout';
import { Icon, MonochromeIcons } from '../../icons';
import { useFade, useScale } from '../../../hooks/transitions';
import { useTheme } from '../../theme/use-theme';
import { forwardRefWrapper } from '../../../libs/forward-ref-wrapper';

import styles from './input.less';

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  disabled?: boolean;
  label?: string;
  errorMessage?: string;
  validateOnBlur?: boolean;
  size?: 'sm' | 'md' | 'lg';
  prefix?: JSX.Element;
  suffix?: JSX.Element;
}

export const Input = /* @__PURE__ */ forwardRefWrapper<HTMLInputElement, InputProps>(
  'Input',

  { size: 'md' },

  (props, externalRef) => {
    const { id, required, disabled, label, errorMessage, validateOnBlur, size, prefix, suffix, ...otherProps } = props;

    const [hasError, setHasError] = useState(!!errorMessage);

    useEffect(() => {
      setHasError(!!errorMessage);
    }, [errorMessage]);

    const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        if (validateOnBlur) {
          const valid = e.target.checkValidity();
          setHasError(!valid || !!errorMessage);
        }
      },
      [errorMessage],
    );

    const [isFocused, onFocusChange] = useState(false);
    const { focusProps } = useFocus({ isDisabled: disabled, onBlur, onFocusChange });
    const inputRef = useRef<HTMLInputElement | null>(null);
    const focusOnInput = useCallback(() => {
      if (inputRef.current) inputRef.current.focus();
    }, []);

    const getScaleProps = useScale();
    const getFadeProps = useFade();
    const theme = useTheme();

    return (
      <FlexColumn>
        {label && (
          <Outset bottom={8}>
            <label htmlFor={id} className={classNames([styles.InputLabel, styles[`size_${size}`]])}>
              <FlexRow alignItems="flex-end">
                <Outset right={3} trim="horizontal">
                  <span>{label}</span>
                  {required && <Icon size={10} color={theme.hex.primary.base} type={MonochromeIcons.AsteriskBold} />}
                </Outset>
              </FlexRow>
            </label>
          </Outset>
        )}

        <FlexRow
          alignItems="center"
          onClick={focusOnInput}
          className={classNames([
            styles.Input,
            styles[`size_${size}`],
            disabled && styles.disabled,
            isFocused && styles.focus,
            hasError && styles.hasError,
          ])}
        >
          <AnimatePresence>
            {isFocused && (
              <motion.span
                aria-hidden="true"
                className={styles.focusRing}
                {...getFadeProps()}
                transition={{ duration: 0.3 }}
              />
            )}
          </AnimatePresence>

          <AnimatePresence initial={false} exitBeforeEnter>
            {prefix && (
              <motion.div className={styles.prefix} {...getScaleProps(0.7)} key={0}>
                {prefix}
              </motion.div>
            )}
          </AnimatePresence>

          <input id={id} {...mergeProps(focusProps, otherProps)} ref={composeRefs(inputRef, externalRef)} />

          <AnimatePresence initial={false} exitBeforeEnter>
            {suffix && !hasError && (
              <motion.div className={styles.suffix} {...getScaleProps(0.7)} key={0}>
                {suffix}
              </motion.div>
            )}

            {hasError && (
              <motion.div className={styles.suffix} {...getScaleProps(0.7)} key={1}>
                <Icon type={MonochromeIcons.Exclaim} color={theme.hex.error.base} />
              </motion.div>
            )}
          </AnimatePresence>
        </FlexRow>

        {errorMessage && (
          <Outset top={8} left={7}>
            <span className={classNames([styles.InputErrorMessage, styles[`size_${size}`]])}>{errorMessage}</span>
          </Outset>
        )}
      </FlexColumn>
    );
  },
);
