import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const ArrowRight: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M11.8084 19.8644L11.0953 19.1774C10.917 18.9966 10.917 18.7073 11.0953 18.5627L16.6217 12.922H4.42786C4.21393 12.922 4 12.7412 4 12.4881V11.4757C4 11.2588 4.21393 11.0418 4.42786 11.0418H16.6217L11.0953 5.43729C10.917 5.29266 10.917 5.00339 11.0953 4.8226L11.8084 4.13559C11.951 3.9548 12.2362 3.9548 12.4145 4.13559L19.8663 11.6927C20.0446 11.8734 20.0446 12.1266 19.8663 12.3073L12.4145 19.8644C12.2362 20.0452 11.951 20.0452 11.8084 19.8644Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
