import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const Smile: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M12 2C6.47581 2 2 6.47581 2 12C2 17.5242 6.47581 22 12 22C17.5242 22 22 17.5242 22 12C22 6.47581 17.5242 2 12 2ZM12 20.7097C7.16129 20.7097 3.29032 16.8387 3.29032 12C3.29032 7.20161 7.16129 3.29032 12 3.29032C16.7984 3.29032 20.7097 7.20161 20.7097 12C20.7097 16.8387 16.7984 20.7097 12 20.7097ZM15.629 14.8226C14.7016 15.9113 13.371 16.5161 12 16.5161C10.5887 16.5161 9.25806 15.9113 8.33065 14.8226C8.12903 14.5403 7.72581 14.5403 7.44355 14.7419C7.16129 14.9839 7.12097 15.3871 7.3629 15.6694C8.49194 17.0403 10.1855 17.8065 12 17.8065C13.7742 17.8065 15.4677 17.0403 16.5968 15.6694C16.8387 15.3871 16.7984 14.9839 16.5161 14.7419C16.2742 14.5403 15.8306 14.5403 15.629 14.8226ZM8.77419 11.3548C9.45968 11.3548 10.0645 10.7903 10.0645 10.0645C10.0645 9.37903 9.45968 8.77419 8.77419 8.77419C8.04839 8.77419 7.48387 9.37903 7.48387 10.0645C7.48387 10.7903 8.04839 11.3548 8.77419 11.3548ZM15.2258 11.3548C15.9113 11.3548 16.5161 10.7903 16.5161 10.0645C16.5161 9.37903 15.9113 8.77419 15.2258 8.77419C14.5 8.77419 13.9355 9.37903 13.9355 10.0645C13.9355 10.7903 14.5 11.3548 15.2258 11.3548Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
