import React from 'react';
import { MonochromeIconDefinition } from '../icon-base';

export const QuestionFilled: MonochromeIconDefinition = {
  SVGContents: ({ fill }) => {
    return (
      <path
        d="M22 12C22 6.51613 17.4839 2 12 2C6.47581 2 2 6.51613 2 12C2 17.5242 6.47581 22 12 22C17.4839 22 22 17.5242 22 12ZM12.2419 5.30645C14.5403 5.30645 16.9597 7.08065 16.9597 9.41936C16.9597 12.5645 13.6129 12.6048 13.6129 13.7339V13.7742C13.6129 14.0565 13.371 14.2581 13.129 14.2581H10.871C10.5887 14.2581 10.3871 14.0565 10.3871 13.7742V13.6129C10.3871 11.9597 11.6371 11.3145 12.5645 10.7903C13.371 10.3468 13.8952 10.0242 13.8952 9.41936C13.8952 8.6129 12.8468 8.08871 12.0403 8.08871C10.9516 8.08871 10.4677 8.6129 9.74194 9.54032C9.58065 9.74194 9.25806 9.78226 9.05645 9.62097L7.64516 8.57258C7.44355 8.41129 7.40323 8.12903 7.56452 7.8871C8.65323 6.23387 10.0645 5.30645 12.2419 5.30645ZM12 15.3065C13.0081 15.3065 13.8548 16.1532 13.8548 17.1613C13.8548 18.2097 13.0081 19.0161 12 19.0161C10.9516 19.0161 10.1452 18.2097 10.1452 17.1613C10.1452 16.1532 10.9516 15.3065 12 15.3065Z"
        {...fill}
      />
    );
  },

  viewbox: [0, 0, 24, 24],
};
