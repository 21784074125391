import React from 'react';
import { DuotoneIconDefinition } from '../icon-base';

export const Envelope: DuotoneIconDefinition = {
  SVGContents: ({ fillA, fillB }) => {
    return (
      <>
        <circle cx="28" cy="28" r="28" {...fillB} />
        <path
          d="M40.6484 19H15.3516C14.8874 19 14.6738 19.5775 15.0262 19.8796L27.3492 30.4422C27.7237 30.7632 28.2763 30.7632 28.6508 30.4422L40.9738 19.8796C41.3262 19.5775 41.1126 19 40.6484 19Z"
          {...fillA}
        />
        <path
          d="M21.2809 28.7593L14.8254 34.2925C14.5011 34.5705 14 34.3401 14 33.9129V22.0871C14 21.6599 14.5011 21.4295 14.8254 21.7075L21.2809 27.2407C21.7465 27.6398 21.7465 28.3602 21.2809 28.7593Z"
          {...fillA}
        />
        <path
          d="M34.7191 27.2407L41.1746 21.7075C41.4989 21.4295 42 21.6599 42 22.0871V33.9129C42 34.3401 41.4989 34.5705 41.1746 34.2925L34.7191 28.7593C34.2535 28.3602 34.2535 27.6398 34.7191 27.2407Z"
          {...fillA}
        />
        <path
          d="M27.3492 32.4422L23.9841 29.5578C23.6096 29.2368 23.057 29.2368 22.6825 29.5578L15.0262 36.1204C14.6738 36.4225 14.8874 37 15.3516 37H40.6484C41.1126 37 41.3262 36.4225 40.9738 36.1204L33.3175 29.5578C32.943 29.2368 32.3904 29.2368 32.0159 29.5578L28.6508 32.4422C28.2763 32.7632 27.7237 32.7632 27.3492 32.4422Z"
          {...fillA}
        />
      </>
    );
  },

  viewbox: [0, 0, 56, 56],

  colorA: (theme) => theme.hex.primary.darker,
  colorB: (theme) => theme.hex.primary.lightest,
};
